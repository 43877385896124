<template>
<div class="absolute left-0 top-0 bottom-0 w-full h-full bg-gray-100 align middle">
    <div class="m-auto max-w-sm p-8 rounded bg-white md:mt-48">
        <div class="text-2xl">Verify</div>
        <div class="block mt-4">
            <div class="text-red-500 px-2 py-1 rounded-sm bg-red-100 text-xs">

            </div>
        </div>
        <form @submit.prevent="$router.push({name: 'change-password'})">
            <div class="block mt-4">
                <div class="text-gray-700 text-sm">Enter the Verification OTP code</div>
                <input class="w-full outline-none border-2 rounded-md px-3 py-2 mt-1 focus:bg-blue-50" placeholder="****" type="email" v-model="email" />
            </div>
            <div class="block mt-4">
                <button type="submit" class="px-3 py-2 text-center w-full bg-green-500 text-white rounded-md" id="login">Next</button>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import $ from 'jquery'
import store from "../store";
export default {
}
</script>
