<template>
<div class="fixed left-0 top-0 bottom-0 w-full z-1 bg-black bg-opacity-10 transition" id="aml-rules-add">
    <div class="absolute top-0 bottom-0 right-0 w-1/3 bg-white z-2 p-4 overflow-auto">
        <div class="block mb-4">
            <div class="text-lg">Edit Country </div>
            <div class="text-xs text-gray-500">Update fees and tax {{countryName}}</div>
        </div>
        <form @submit.prevent="updateCountry">
        <div class="block my-6">
            <label for="" class="text-sm mb-1 block">Fee</label>
            <input class="text-xs border border-gray-300 px-3 py-2 w-full rounded-md focus:outline-none" type="number" step="0.01" v-model="fee" required/>
        </div>
         <div class="block my-6">
            <label for="" class="text-sm mb-1 block">Tax</label>
            <input class="text-xs border border-gray-300 px-3 py-2 w-full rounded-md focus:outline-none" type="number" v-model="tax" min="0" max="100" required/>
        </div>
        <div class="block mt-16">
            <button class="px-3 py-2 rounded-md border text-sm focus:outline-none text-gray-700 bg-gray-200 mr-4" type="button" @click="hideModal">Cancel</button>
            <button class="px-3 py-2 rounded-md border text-sm focus:outline-none text-white bg-green-500" type="submit" id="createRule">Update Country</button>
        </div>
        </form>
    </div>
</div>
</template>

<script>
import $ from "jquery";
import store from "../store";
export default {
    data: function () {
        return {
            countryName: '',
            fee:0.00,
            tax:0,
            countryCode:"",
            currency:"",
            errorMessage:"",
            errorSatus:false,
        }
    },
     mounted() {

    },
    computed: {
         country() {
            return store.state.country
        }
    },
    watch: {
        country(newCount, oldCount) {
            console.log(`We have ` + JSON.stringify(newCount))
            this.countryName = newCount.name
            this.countryCode =  newCount.code
            this.tax =  newCount.tax
            this.fee =  newCount.fees
            
        }
    },

    methods: {
        hideModal() {
            this.$parent.modalEditCountry = false;
        },
        updateCountry() {
            let vm  = this
            $('#createRule').html('<i class="fa fa-spinner fa-spin"></i> Please wait ...');
            $.post({
                url: store.state.rootUrl + 'v1/finance/fee/update',
                type: "POST",
               
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+ vm.$cookies.get("accessToken")
                },
                data: JSON.stringify({
                    countryCode: vm.countryCode,
                    fee: vm.fee,
                    tax: vm.tax

                }),
                success: function (response, status, jQxhr) {
                    // console.log(JSON.stringify(response.data.data));
                    $('#createRule').text('Create Account')
                    if (response.responseCode != 200) {
                        vm.errorSatus = true;
                        vm.errorMessage = response.message;
                    } else {
                        vm.hideModal()
                        vm.$parent.loadCountry(vm.country)
                        vm.$swal('Sent',response.message , 'success')
                        
                    }

                },
                error: function (jQxhr, status, error) {
                    var errorMessage = jQxhr.responseJSON.errors[0];
                    var statustext = jQxhr.responseJSON.message;
                    vm.errorSatus = true;
                    $('#createRule').text('Create Account')
                    if (!statustext) {
                        vm.errorMessage = "An error occurred. Try again later.";
                    } else {
                        vm.errorMessage = statustext + " " + errorMessage;
                    }
                }
            });
            
        },
        amlOptions(){
             let vm  = this
              $.get({
                url: store.state.rootUrl + 'v1/aml/select',
                type: "GET",
               
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+ vm.$cookies.get("accessToken")
                },
                data: {

                },
                success: function (response, status, jQxhr) {
                    // console.log(JSON.stringify(response.data.data));
                    if (response.responseCode != 200) {
                    
                        vm.errorSatus = true;
                        vm.errorMessage = response.message;
                    } else {
                        vm.documents = response.data.documents
                        vm.periodTypes = response.data.periodType
                        vm.limitTypes = response.data.limitType
                        vm.actions = response.data.action
                        vm.countriesDetails = response.data.countries

                    }

                },
                error: function (jQxhr, status, error) {
                    var errorMessage = jQxhr.responseJSON.errors[0];
                    var statustext = jQxhr.responseJSON.message;
                    vm.errorSatus = true;
                    if (!statustext) {
                        vm.errorMessage = "An error occurred. Try again later.";
                    } else {
                        vm.errorMessage = statustext + " " + errorMessage;
                    }
                }
            });
        }
    },
    
}
</script>
