<template>
<div class="fixed left-0 top-0 bottom-0 w-full z-1 bg-black bg-opacity-10 transition" id="aml-rules-add">
    <div class="absolute top-0 bottom-0 right-0 w-1/3 bg-white z-2 p-4 overflow-auto">
        <div class="block mb-4">
            <div class="text-lg">Transaction</div>
           
        </div>
        <form @submit.prevent="updateAccount">
            <div class="block my-6">
                <label for="" class="text-sm mb-1 block">Exchange Rate</label>
                <input class="text-xs border border-gray-300 px-3 py-2 w-full rounded-md focus:outline-none" v-model="rate" disabled />
            </div>
           
            <div class="block my-6 py-2">
                <div class="text-sm text-gray-500 mb-1">Inflow Reason</div>
                <textarea class="rounded-md h-24 border outline-none align-top p-3 w-full bg-blue-50 mb-2" v-model="inflowreason" disabled></textarea>
            </div>
            <div class="block my-6 py-2">
                <div class="text-sm text-gray-500 mb-1">Outflow Reason</div>
                <textarea class="rounded-md h-24 border outline-none align-top p-3 w-full bg-blue-50 mb-2" v-model="outflowReason" disabled></textarea>
            </div>

             <div class="block mt-16">
                <button class="px-3 py-2 rounded-md border text-sm focus:outline-none text-gray-700 bg-gray-200 mr-4" type="button" @click="hideModal">Close</button>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import $ from "jquery";
import store from "../store";
export default {
    data: function () {
        return {
            rate: '',
            inflowreason: '',
            outflowReason: '',
            errorMessage: "",
            errorSatus: false,
        }
    },
    computed: {
        account() {
            return store.state.selectedTransactions
        }
    },
    watch: {
        account(newCount, oldCount) {
            console.log(`We have ` + JSON.stringify(newCount))
            this.rate = newCount.payouts.destinationExhangeRate
            this.inflowreason = newCount.payouts.inflowMessage
            if(newCount.payouts.inflowStatus==1 && newCount.payouts.outflowStatus == 0) {
                this.outflowReason = "Transaction is onhold. Awaiting for approval"
            }
            else {
                this.outflowReason = newCount.payouts.outflowMessage
            }
            
            
        }
    },

    methods: {
        hideModal() {
            this.$parent.modalTransactions = false;
        },
       
    },

}
</script>
