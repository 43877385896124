<template>
<div class="relative">
    <div class="sticky top-0 bg-gray-50 p-4 border-b z-3">
        <div class="relative w-full items-center my-2 border-2 rounded-md bg-white focus:shadow-md">
            <div class="flex">
                <input class="px-4 py-2 text-lg flex-grow outline-none" placeholder="Search by Phone number or Email" v-model="phoneNumber" v-on:keyup.enter="search_profile" />
                <button class="flex-shrink p-2 rounded-md bg-white mx-1 my-1 text-sm" v-show="phoneNumber!=''" @click="phoneNumber=''"><svg viewBox="0 0 18 18" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                        <path fill="none" d="M0 0h18v18H0Zm0 0h18v18H0Z" />
                        <path fill="#777" d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9Z" /></svg></button>
                <button class="flex-shrink px-4 py-2 rounded-md bg-green-100 text-green-700 mx-1 my-1 text-sm" @click="search_profile">Search</button>
            </div>

            <div class="h-auto w-full absolute t-10 bg-white border-l-2 border-r-2 border-b-2 rounded-b-md focus:active:shadow-md hidden">
                <div class="px-4 py-2 border-t hover:bg-blue-50 cursor-pointer text-sm text-gray-500" v-for="(search,index) in searchData" :key="index">Wilson Gichuki - <span class="text-gray-600">0725793151</span></div>
            </div>
        </div>
    </div>
    <loader v-show="isLoading"></loader>

    <div class="block bg-gray-100 pt-6 px-6" v-show="showDetails">
        <div class="flex items-top">
            <img src="@/assets/images/iconUser.png" class="h-24 bg-white border-gray-300 border rounded-full" v-show="!selfie"/>
            <img :src="selfie" class="w-24 h-24 bg-white border-gray-300 border rounded-full" v-show="selfie"/>
            <div class="ml-4 flex-grow">
                <div class="text-xl font-medium">{{name}}</div>
                <div class="text-md mt-1">
                    <span class="text-gray-800 mr-6 inline-block align-middle"><svg class="align-middle inline-block" width="12" height="12" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 2C7.589 2 4 5.58 4 9.99c-.03 6.44 7.696 11.78 8 12 0 0 8.029-5.56 8-12 0-4.411-3.59-8-8-8Zm0 12c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4 -1.79 4-4 4Z"/></svg> {{country}}</span>
                    <span class="text-gray-600 mr-6 inline-block align-middle">{{phone}}</span>
                    <span class="text-gray-600 mr-6 inline-block align-middle">{{email}}</span>
                </div>
                <div class="mt-2" v-show="documentFront && (isAdmin || isFinance)">Document <span>{{documentType}}</span>: <a :href="documentFront" download target="_blank"><strong>{{documentNumber}} </strong></a> ({{documentAuthenticity}}%)</div>
            </div>
            <div class="block text-right">
                <div class="bg-white inline-block border border-gray-300 text-sm rounded-sm px-2 mb-2 py-1 align-middle" v-show="approveStatus == 2">Face Match: {{faceMatch}}%</div>
                <div class="bg-white inline-block border border-gray-300 text-sm rounded-sm px-2 mb-2 py-1 align-middle" >Status: <strong>{{approveStatus | amlStatus}}</strong></div>
                 <div class="bg-white inline-block border border-gray-300 text-sm rounded-sm px-2 mb-2 py-1 align-middle" v-show="approvalType">Approval Type: <strong>{{approvalType}}</strong></div>
                
                <div class="items-end text-right gap-2 text-sm">
                    <div class="bg-white inline-block border border-gray-300 rounded-sm px-2 py-1 ml-2">AML: {{amlScore}}</div>
                    <div class="bg-white inline-block border border-gray-300 rounded-sm px-2 py-1 ml-2">Sift: {{siftScore}}</div>
                    <div class="bg-white inline-block border border-gray-300 rounded-sm px-2 py-1 ml-2">Seon: {{seonScore}}</div>
                </div>
                <div class="bg-white inline-block border border-gray-300 text-sm rounded-sm px-2 mb-2 py-1 align-middle" v-show="approved_by">Approved By: <strong>{{ approved_by }}</strong> </div>
                <p v-show="approveReason">Approval Reason: <strong> {{approveReason}} </strong></p>
                
            </div>
        </div>
        <div class="mt-4">
            <button class="px-4 py-2 bg-red-500 text-white rounded-md text-sm hover:bg-red-600 transition mt-3" @click="showTicketCreate()">Report issue</button>
            <button class="px-4 py-2 bg-yellow-500 text-white rounded-md text-sm hover:bg-yellow-600 transition mt-3 text-right"" v-show="approveStatus == 1 && (isAdmin || isFinance)" @click="approveAccount()">Approve Account</button>
        </div>
        <ul class="list-inline flex gap-6 mt-6 border-b border-gray-300">
            <li class="py-2 cursor-pointer text-gray-700" :class="{'border-b-2 border-green-500':activeTab=='transactions'}" @click="activeTab ='transactions'">Transactions</li>&nbsp;&nbsp;&nbsp;
            <li class="py-2 cursor-pointer text-gray-500" :class="{'border-b-2 border-green-500':activeTab=='tickets'}" @click="activeTab ='tickets'">Tickets</li>
        </ul>
    </div>

    <div class="block relative px-2 border-b" v-show="showDetails">
        <div class="block p-4" v-show="activeTab=='transactions'">
            <vuetable ref="vuetable" :css="cssTable" :http-options="httpOptionsTransaction" :fields="columnsTransaction" :sort-order="sortOrderTransaction" track-by="id" :append-params="moreParamsTransaction" :per-page="limit" @vuetable:pagination-data="onPaginationData" @vuetable:loading="onLoading" @vuetable:loaded="onLoaded">
                <div slot="payoutsstatus" slot-scope="props">
                    <div :class="props.rowData.payouts.status | transClassStatus"><strong>{{props.rowData.payouts.status | transactionsStatus}}</strong></div>
                </div>
                <div slot="payoutsinflowStatus" slot-scope="props">
                    <div :class="props.rowData.payouts.inflowStatus | transClassStatus"><strong>{{props.rowData.payouts.inflowStatus | transactionsStatus}}</strong></div>
                </div>
                <div slot="payoutsoutflowStatus" slot-scope="props">
                        <div :class="props.rowData.payouts.outflowStatus | transClassStatus" v-show="props.rowData.payouts.inflowStatus == 1"><strong>{{props.rowData.payouts.outflowStatus | transactionOutflowStatus}}</strong></div>
                        <div :class="props.rowData.payouts.outflowStatus | transClassStatus" v-show="props.rowData.payouts.inflowStatus != 1"><strong>{{props.rowData.payouts.outflowStatus | transactionsStatus}}</strong></div>
                </div>

                <div slot="Sender" slot-scope="props">
                    <div class="">{{props.rowData.customers.names}}</div>
                    <div class="text-gray-500">{{props.rowData.customers.phone}}</div>

                </div>
                <div slot="full_names" slot-scope="props">
                    <div class="">{{props.rowData.benefitiaries.full_names}}</div>
                    <div class="text-gray-500" v-show="props.rowData.benefitiaries.payout_type =='Bank Payments'">{{props.rowData.benefitiaries.bank_name}}</div>
                    <div class="text-gray-500" v-show="props.rowData.benefitiaries.payout_type =='Bank Payments'">{{props.rowData.benefitiaries.bank_account}}</div>
                    <div class="text-gray-500" v-show="props.rowData.benefitiaries.payout_type =='Mobile Payments'">{{props.rowData.benefitiaries.phone}}</div>
                </div>
                <div slot="senderAmount" slot-scope="props">
                    <div class="text-sm font-medium">{{props.rowData.customers.country}} {{props.rowData.payouts.sendAmount | formatAmount}}</div>
                    <div class="text-xs text-gray-600">Charged: USD {{props.rowData.payouts.payAmount | formatAmount}}</div>

                </div>
                <div slot="receiveAmount" slot-scope="props">
                <div class="text-sm font-medium"> KES {{props.rowData.payouts.payoutAmount | formatAmount}}</div>
                <div class="text-xs text-gray-600">Exchange Rate KES {{props.rowData.payouts.destinationExhangeRate | formatAmount}}</div>

                    

                </div>
                <div class="table-button-container" slot="action" slot-scope="props">
                    <button class="text-green-500" @click="showTransactionsDetails(props.rowData)"><i class="fa fa-eye" aria-hidden="true"></i> More Info </button>
                </div>

            </vuetable>
            <div class="block">
                <vuetable-pagination-info :css="paginationInfo" ref="paginationInfo"></vuetable-pagination-info>
                <vuetable-pagination ref="pagination" :css="pagination" @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
            </div>
        </div>
        <div class="block p-4" v-show="activeTab=='tickets'">
            <vuetable ref="vuetableTickets" :css="cssTable" :http-options="httpOptionsTickets" :fields="columnsTickets" :sort-order="sortOrderTickets" track-by="id" :append-params="moreParamsTickets" :per-page="limit" @vuetable:pagination-data="onPaginationDataTickets" @vuetable:loading="onLoading" @vuetable:loaded="onLoaded">
                <div slot="status" slot-scope="props">
                    <div :class="props.rowData.status | ticketClassStatus"><strong>{{props.rowData.status | formatTicketStatus}}</strong></div>
                </div>
                <div slot="assigned" slot-scope="props">
                    <div><strong>{{props.rowData.assigned_user.name}}</strong></div>
                </div>
                <div slot="importance" slot-scope="props">
                    <div class="">{{props.rowData.importance}}</div>

                </div>

                <div slot="created_at" slot-scope="props">

                    <div class="text-xs text-gray-600"> {{props.rowData.created_at | formatDate}}</div>

                </div>
                <div class="table-button-container" slot="action" slot-scope="props">
                    <button class="text-green-500" @click="showTicketDetails(props.rowData)"><i class="fa fa-check" aria-hidden="true"></i> View Ticket </button>
                </div>

            </vuetable>
            <div class="block">
                <vuetable-pagination-info :css="paginationInfo" ref="paginationInfoTickets"></vuetable-pagination-info>
                <vuetable-pagination ref="paginationTickets" :css="pagination" @vuetable-pagination:change-page="onChangePageTicket"></vuetable-pagination>
            </div>
        </div>
    </div>
    <TicketDetails v-show="this.modalTickets == true" />
    <TicketCreate v-show="this.modalTicketCreate == true" />
    <ApproveAccount v-show="showApproveAccount" />
    <Transaction  v-show="modalTransactions" />
</div>
</template>

<style scoped>
@import "https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css";
@import "https://cdn.datatables.net/1.10.20/css/jquery.dataTables.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css";
</style>

<script>
import ApproveAccount from './modal-approve-account';
import TicketDetails from "./modal-ticket";
import TicketCreate from "./modal-ticket-create"
import Transaction from "./modal-transactions"
import $ from 'jquery'
import store from "../store";
import loader from "@/components/loader";
import moment from "moment";
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import numeral from "numeral";
export default {
    data() {
        return {
            httpOptionsTransaction: {
                baseURL: 'https://api.helasend.com/api/v1/transactions',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + this.$cookies.get("accessToken")
                }
            },
            httpOptionsTickets: {
                baseURL: 'https://api.helasend.com/api/v1/issues/all',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + this.$cookies.get("accessToken")
                }
            },
            cssTable: {
                tableWrapper: "",
                tableHeaderClass: "mb-0",
                tableBodyClass: "mb-0",
                loadingClass: 'loading',
                ascendingIcon: "fa fa-chevron-up",
                descendingIcon: "fa fa-chevron-down",
                ascendingIcon: 'green chevron up icon',
                descendingIcon: 'green chevron down icon',
                sortableIcon: "",
                detailRowClass: "vuetable-detail-row",
                handleIcon: "fa-bars text-secondary",
                renderIcon(classes, options) {
                    return `<i class="${classes.join(" ")}" ${options}></span>`;
                }
            },
            pagination: {
                wrapperClass: 'ui right floated pagination menu',
                activeClass: 'active',
                disabledClass: 'disabled',
                pageClass: 'item',
                linkClass: 'icon item',
                paginationClass: 'ui bottom attached segment grid',
                paginationInfoClass: 'left floated left aligned six wide column',
                dropdownClass: 'ui search dropdown',
                icons: {
                    first: 'angle double left icon',
                    prev: 'left chevron icon',
                    next: 'right chevron icon',
                    last: 'angle double right icon',
                }
            },
            paginationInfo: {
                infoClass: 'left floated left aligned six wide column',
            },
            columnsTransaction: [{
                    name: "payouts.id",
                    title: 'ID',
                    sortField: 'payouts.id',
                },
                {
                    name: "payoutsstatus",
                    title: 'Overal Status',
                    sortField: 'status',
                },
                {
                    name: "payoutsinflowStatus",
                    title: 'Inflow Status',
                    sortField: 'inflowStatus',
                },
                {
                    name: "payoutsoutflowStatus",
                    title: 'Outflow Status',
                    sortField: 'outflowStatus',
                },
                {
                    name: "payouts.transactionReference",
                    title: 'Ref',
                    sortField: 'payouts.transactionReference',
                },
                {
                    name: "Sender",
                    title: 'Sender',
                },
                {
                    name: 'full_names',
                    title: 'Full Names'
                },
                {
                    name: 'benefitiaries.payout_type',
                    title: 'Payout Types'
                },
                {
                    name: 'senderAmount',
                    title: 'Sender Amount'
                },
                {
                    name: 'receiveAmount',
                    title: 'Receive Amount'
                },
              
                {
                    name: 'created_at',
                    title: 'Date',
                    sortField: 'created_at',
                    titleClass: '',
                    dataClass: ''

                },
                {
                    name: 'action',
                    title: 'Action'
                },

            ],
            columnsTickets: [{
                    name: "status",
                    title: 'Status',
                    sortField: 'status',
                },
                {
                    name: "type",
                    title: 'Type',
                    sortField: 'type',
                },
                {
                    name: "importance",
                    title: 'Importance',
                    sortField: 'importance',
                },

                {
                    name: 'created_at',
                    title: 'Date',
                    sortField: 'created_at',
                    titleClass: '',
                    dataClass: ''

                },
                'action'

            ],
            sortOrderTransaction: [{
                field: 'created_at',
                direction: 'desc'
            }],
            sortOrderTickets: [{
                field: 'created_at',
                direction: 'desc'
            }],
            is_partial_resulting: false,
            moreParamsTickets: {
                start: '',
                filter: '',
                end: '',
            },
            moreParamsTransaction: {
                start: '',
                filter: '',
                end: '',
            },
            modalTickets: false,
            modalTicketCreate: false,
            showDetails: false,
            errorStatus: false,
            errorMessage: "",
            phoneNumber: "",
            isLoading: false,
            sort: "desc",
            name: "",
            email: "",
            phone: "",
            country: "",
            activeTab: "transactions",
            transactions: [],
            page: 1,
            total: 0,
            limit: 5,
            totalPages: 0,
            tickets: [],
            pageTicket: 1,
            totalTicket: 0,
            limitTicket: 10,
            totalPagesTicket: 0,
            searchData: [],
            amlScore: 0,
            documentType: "",
            documentNumber: "",
            faceMatch: 0,
            documentAuthenticity: 0,
            liveness: 0,
            selfie: "",
            faceImage: "",
            documentFront: "",
            documentBack: "",
            approveStatus: 0,
            seonScore:0,
            siftScore:0,
            isAdmin: false,
            isSupport: false,
            isFinance: false,
            showApproveAccount: false,
            userInfo:[],
            approvalType:'',
            approveReason:"",
            approved_by:null,
            modalTransactions: false

        }
    },
    components: {
        TicketDetails,
        TicketCreate,
        loader,
        Vuetable,
        VuetablePagination,
        VuetablePaginationInfo,
        ApproveAccount,
        Transaction
    },
    methods: {
        showTransactionsDetails(transactions) {
           // console.log(JSON.stringify(ticket))
            store.commit("setSelectedTransactions", transactions);
            this.modalTransactions = true;

        },
        showTicketDetails(ticket) {
            store.commit("setTicketInformation", ticket);
            this.modalTickets = true;
        },
        showTicketCreate() {
            console.log(JSON.stringify(store.state.userData))
            this.modalTicketCreate = true;
        },
        approveAccount(){
            store.commit("setUserInformation", this.userInfo);
            this.showApproveAccount = true
        },

        search_profile() {
            let vm = this
            vm.name = ""
            vm.phone = ""
            vm.email = ""
            vm.country = ""
            vm.page = 1
            vm.total = 0
            vm.limit = 5
            vm.getUserInformation()
            this.moreParamsTransaction.filter = vm.phoneNumber
            this.moreParamsTickets.filter = vm.phoneNumber
            vm.$refs.vuetable.refresh()
            vm.$refs.vuetableTickets.refresh()
            //vm.getAllTickets(vm.pageTicket)

        },
        getUserInformation() {
            let vm = this
             if(vm.$cookies.get("roleID") == 1){
                vm.isAdmin = true
            }
            if(vm.$cookies.get("roleID") == 2){
                vm.isSupport = true
            }
            if(vm.$cookies.get("roleID") == 3){
                vm.isFinance = true
            }
            vm.isLoading = true
            $.get({
                url: store.state.rootUrl + 'v1/customers',
                type: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + vm.$cookies.get("accessToken")
                },
                data: {
                    filter: vm.phoneNumber,
                    per_page: 1,

                },
                success: function (response, status, jQxhr) {
                    vm.isLoading = false
                    if (response.links.pagination.total == 0) {
                        vm.errorStatus = true;
                        vm.errorMessage = "NO Transaction Found"
                    } else {
                        var list_c = response.data;
                        store.commit("setUserInformation", list_c[0]);
                        vm.userInfo=list_c[0]
                        vm.name = list_c[0].names
                        vm.phone = list_c[0].phone,
                        vm.email = list_c[0].email
                        vm.country = list_c[0].country
                        vm.showDetails = true
                        vm.amlScore = list_c[0].amlScore
                        vm.documentType = list_c[0].type
                        vm.documentNumber = list_c[0].number
                        vm.faceMatch = list_c[0].faceMatch
                        vm.documentAuthenticity = list_c[0].documentAuthenticity
                        vm.liveness = list_c[0].liveness
                        vm.selfie = list_c[0].selfie
                        vm.faceImage = list_c[0].faceImage
                        vm.documentFront = list_c[0].documentFront
                        vm.documentBack = list_c[0].documentBack
                        vm.approveStatus = list_c[0].approved
                        vm.seonScore = list_c[0].seonScore
                        vm.siftScore = list_c[0].siftScore
                        vm.approvalType = list_c[0].approveType
                        vm.approveReason = list_c[0].approveReason
                        vm.approved_by = list_c[0].approved_by.name

                    }
                },
                error: function (jQxhr, status, error) {
                    var errorMessage = jQxhr.responseJSON.errors[0];
                    var statustext = jQxhr.responseJSON.message;
                    vm.errorStatus = true;
                    vm.isLoading = false
                    $('#login').text('login')
                    if (!statustext) {
                        vm.errorMessage = "An error occurred. Try again later.";
                    } else {
                        vm.errorMessage = statustext + " " + errorMessage;
                    }
                }
            });
        },
        onPaginationData(paginationData) {
            this.$refs.paginationInfo.setPaginationData(paginationData)
            this.$refs.pagination.setPaginationData(paginationData)
        },
        onPaginationDataTickets(paginationData) {
            this.$refs.paginationInfoTickets.setPaginationData(paginationData)
            this.$refs.paginationTickets.setPaginationData(paginationData)
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page)
        },
        onChangePageTicket(page) {
            this.$refs.vuetableTickets.changePage(page)
        },
        onLoading() {
            this.loading = true
            console.log('loading... show your spinner here')
        },
        onLoaded() {
            this.loading = false
            console.log('loaded! .. hide your spinner here');

        },
    },
    filters: {
         amlStatus(value) {
            if (value == 1) {
                return "In Progress"
            } else if (value == 2) {
                return "Active"
            } else {
                return "Pending"
            }
        },
        amlClassStatus(value) {
            if (value == '1') {
                return "text-yellow-500"
            } else if(value == '2'){
                return "text-green-500"
            }
            else {
                return "text-blue-500"
            }
        },
        formatDate(value) {
            if (!value) return "";
            return moment(value).format("DD MMM YYYY");
        },
        transactionsStatus(value) {
            if (value == 1) {
                return "Delivered"
            } else if (value == 2) {
                return "On Hold"
            } else if (value == 3) {
                return "Rejected"
            }
            else if (value == -2) {
                return "Failed"
            }
            else if (value == -7) {
                return "Refunded"
            } else {
                return "Pending"
            }
        },
        transactionOutflowStatus(value){
            if (value == 1) {
                return "Delivered"
            } else if (value == 0 || value == 2) {
                return "On Hold"
            } else if (value == 3) {
                return "Rejected"
            }
            else if (value == -2) {
                return "Failed"
            }
            else if (value == -7) {
                return "Refunded"
            } else {
                return "Pending"
            }
        },
        transactionsInOutStatus(value) {
            if (value == 1) {
                return "Success"
            }
            else if (value == -7) {
                return "warning"
            }
             else {
                return "Failed"
            }
        },
        transClassStatus(value) {
            if (value == '1') {
                return "text-green-500"
            } else if (value == '2' || value == '-7' || value == '0') {
                return "text-yellow-500"
            } else if (value == '3' || value == '-2') {
                return "text-red-500"
            } else {
                return "text-gray-500"
            }
        },
        formatTicketStatus(value) {

            if (value == '3') {
                return "closed"
            } else if (value == '2') {
                return "reopen"
            } else {
                return "pending"
            }
        },
        ticketClassStatus(value) {
            if (value == '3') {
                return "text-green-500"
            } else if (value == '0') {
                return "text-yellow-500"
            } else if (value == '2') {
                return "text-red-500"
            } else {
                return "text-gray-500"
            }
        },
        formatAmount(value) {
            return numeral(value).format("0,0.00");
        },
    }
}
</script>
