<template>
<div class="relative p-4">
    <div class="flex w-full pb-4 items-center">
        <div class="flex-shrink">
            <div class="text-lg">Funding</div>
            <div class="text-xs text-gray-500">-</div>
        </div>
        <div class="flex-grow text-right">
            <div class="inline-block mr-4"><input class="px-3 py-2 rounded-md border text-xs focus:outline-none text-gray-700 bg-white focus:border-gray-400" placeholder="Search"></div>
            <button class="inline-block focus:outline-none px-3 py-2 rounded-md bg-green-500 text-xs text-white  items-center mr-4 border border-gray-300" @click="showModal()"><span>Add Accounts</span></button>
        </div>
    </div>
    <div class="block">
    <loader v-show="isLoading"></loader>
        <table class="w-full" v-show="!isLoading">
            <thead>
                <tr class="bg-gray-50 text-sm text-left font-normal border-b">
                    <th class="py-2 px-4 border-r">Status</th>
                    <th class="py-2 px-4 border-r">Account Name</th>
                    <th class="py-2 px-4 border-r text-right">Available Amount</th>
                    
                    <th class="py-2 px-4 border-r">Last updated</th>
                    <th class="py-2 px-4">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr class="items-center border-b" v-for="(account, index) in accounts" :key="index+1">
                    <td class="py-2 px-4 border-r">
                        <div class="inline-block  text-xs px-4 py-1 rounded-full" :class="account.status | accountClassStatus">{{account.status | accountStatus}}</div>
                    </td>
                    <td class="py-3 px-4 border-r">
                        <div class="text-md font-medium">{{account.accountName}}</div>
                        <div class="text-xs">{{account.accountType}} {{account.accountNumber}}</div>
                    </td>
                    <td class="py-3 px-4 border-r text-right">
                        {{account.currency}} {{account.availableBalance | formatAmount}}
                    </td>
                    <td class="py-2 px-4 border-r relative">
                        <div class="text-sm">{{account.updated_at | formatDate}} </div>
                        <div class="text-xs">{{account.updated_at | formatTime}} </div>
                    </td>
                    <td>
                        <button type="submit" class="px-3 py-2 text-center bg-green-500 text-white rounded-md" @click="showModalUpdate(account)">Update Balance</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <AddAccount v-show="modal"></AddAccount>
    <UpdateAccount v-show="showAppAcc"></UpdateAccount>
</div>
</template>

<script>
import AddAccount from "./modal-add-account";
import UpdateAccount from './modal-update-account';
import $ from 'jquery'
import store from "../store";
import moment from "moment";
import loader from "@/components/loader";
import numeral from "numeral";
export default {
    data: function () {
        return {
            accounts: [],
            startDate30: "",
            endDate30: "",
            isLoading: false,
            errorStatus: false,
            errorMessage: "",
            modal:false,
            showAppAcc:false
        }
    },
    components: {
        loader,AddAccount,
        UpdateAccount
    },
    mounted() {
        this.startDate = moment().subtract(7, 'd').format('YYYY-MM-DD');
        this.startDate30 = moment().subtract(30, 'd').format('YYYY-MM-DD');
        this.fetchAccount()

    },
    methods: {
         showModal() {
            this.modal = true;
        },
        showModalUpdate(data){
            store.commit("setAccount", data);
            this.showAppAcc = true
        },
        formatNumber(value) {
            if (!value) return 0
            return numeral(value).format("0,0");
        },
        getReportSearch() {
            let vm = this
            vm.accounts = []
            vm.fetchAccount()

        },
        fetchAccount() {
            let vm = this
            vm.isLoading = true
            $.get({
                url: store.state.rootUrl + 'v1/partner/accounts',
                type: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + vm.$cookies.get("accessToken")
                },
                data: {
                    startDate: vm.startDate30,
                    endDate: vm.endDate30,

                },
                success: function (response) {
                    vm.isLoading = false
                    if (response.responseCode != 200) {
                        vm.errorStatus = true;
                        vm.errorMessage = response.message;
                    } else {
                        vm.accounts = response.data
                    }
                },
                error: function (jQxhr) {
                    var errorMessage = jQxhr.responseJSON.errors[0];
                    var statustext = jQxhr.responseJSON.message;
                    vm.errorStatus = true;
                    vm.isLoading = false
                    $('#login').text('login')
                    if (!statustext) {
                        vm.errorMessage = "An error occurred. Try again later.";
                    } else {
                        vm.errorMessage = statustext + " " + errorMessage;
                    }
                }
            });

        }
    },
    filters: {
        accountStatus(value) {
            if (value == 1) {
                return "Active"
            } else {
                return 'Inactive'
            }
        },
        accountClassStatus(value) {
            if (value == 1) {
                return "bg-green-300"
            } else {
                return "bg-red-300"
            }
        },
        transactionsStatus(value) {
            if (value == 1) {
                return "Delivered"
            } else if (value == 2) {
                return "On Hold"
            } else if (value == 3) {
                return "Rejected"
            } else {
                return "Pending"
            }
        },
        transClassStatus(value) {
            if (value == '1') {
                return "bg-green-200 text-yellow-900"
            } else if (value == '2') {
                return "bg-yellow-200 text-green-900"
            } else if (value == '3') {
                return "bg-red-200 text-red-900"
            } else {
                return "bg-gray-200 text-gray-900"
            }
        },
        formatDate(value) {
            if (!value) return "";
            return moment(value).format("DD MMM YYYY");
        },
        formatTime(value) {
            if (!value) return "";
            return moment(value).format("HH:mm:");
        },
        formatAmount(value) {
            if (!value) return 0
            return numeral(value).format("0,0.00");
        },

    }
}
</script>
