<template>
<div class="fixed left-0 top-0 bottom-0 w-full z-1 bg-black bg-opacity-10 transition" id="aml-rules-add">
    <div class="absolute top-0 bottom-0 right-0 w-1/3 bg-white z-2 p-4 overflow-auto">
        <div class="block mb-4">
            <div class="text-lg">Add AML Rule</div>
            <div class="text-xs text-gray-500">Create AML rules below. After saving, you need to activate them on the AML list.</div>
        </div>
        <div class="block my-4">
            <label for="" class="text-sm mb-1 block">Rule Name</label>
            <input class="text-xs border border-gray-300 px-3 py-2 w-full rounded-md focus:outline-none" v-model="ruleName" />
        </div>
        <div class="block my-4">
            <label for="" class="text-sm mb-1 block">Period</label>
            <div class="flex gap-3">
                <select class="text-xs border border-gray-300 px-3 py-2 w-full block rounded-md focus:outline-none" v-model="periodType">
                    <option v-for="(periodType,index) in periodTypes" :key="index" :value="periodType">{{periodType}}</option>
                </select>
                <input class="text-xs border border-gray-300 px-3 py-2 w-full rounded-md focus:outline-none" type="number" placeholder="0" v-model="period" />
            </div>
        </div>
        <div class="block my-4">
         <label for="" class="text-sm mb-1 block">Action</label>
            <div class="flex gap-3">
                <select class="text-xs border border-gray-300 px-3 py-2 w-full block rounded-md focus:outline-none" v-model="action">
                    <option  v-for="(action,index) in actions" :key="index" :value="action">{{action}}</option>
                </select>
            </div>
        </div>
        <div class="block my-4">
            <label for="" class="text-sm mb-1 block">Amount Limits</label>
            <div class="flex gap-3">
                <input class="text-xs border border-gray-300 px-3 py-2 w-full rounded-md focus:outline-none" type="number" placeholder="limit" v-model="limit" />
                <select class="text-xs border border-gray-300 px-3 py-2 w-full block rounded-md focus:outline-none" v-model="limitType">
                    <option v-for="(limitType,index) in limitTypes" :key="index" :value="limitType">{{limitType}}</option>
                </select>
                <select class="text-xs border border-gray-300 px-3 py-2 w-full block rounded-md focus:outline-none" v-model="currency">
                    <option v-for="(country,index) in countriesDetails" :key="index" :value="country.currency">{{country.currency}}</option>
                </select>
            </div>
        </div>
        <div class="block my-4">
            <label for="" class="text-sm mb-1 block">Location</label>
            <div class="flex gap-3">
                <select class="text-xs border border-gray-300 px-3 py-2 w-full block rounded-md focus:outline-none" v-model="origin">
                    <option>Origin</option>
                    <option  v-for="(country,index) in countriesDetails" :key="index" :value="country.code">{{country.name}}</option>
                </select>
                <select class="text-xs border border-gray-300 px-3 py-2 w-full block rounded-md focus:outline-none" v-model="destination">
                    <option>Destination</option>
                    <option  v-for="(country,index) in countriesDetails" :key="index"  :value="country.code">{{country.name}}</option>
                </select>
            </div>
        </div>
        <div class="block my-4">
            <label for="" class="text-sm mb-1 block">Documents</label>
            <div class="flex gap-3 items-center">
                <select class="text-xs border border-gray-300 px-3 py-2 w-full block rounded-md focus:outline-none" v-model="documentationType">
                    <option v-for="(document,index) in documents" :key="index" :value="document" >{{document}}</option>
                </select>
                <label class="w-2/3 whitespace-nowrap block text-sm ml-4"><input type="checkbox" v-model="documentationRequired" /> Required</label>
            </div>
        </div>
        <div class="block mt-16">
            <button class="px-3 py-2 rounded-md border text-sm focus:outline-none text-gray-700 bg-gray-200 mr-4" @click="hideModal">Cancel</button>
            <button class="px-3 py-2 rounded-md border text-sm focus:outline-none text-white bg-green-500" @click="saveRule" id="createRule">Create Rule</button>
        </div>
    </div>
</div>
</template>

<script>
import $ from "jquery";
import store from "../store";
export default {
    data: function () {
        return {
            ruleName: '',
            period: '',
            periodType: false,
            action: '',
            limit: 1,
            limitType: "",
            currency: "",
            documentationRequired: "",
            documentationType: "",
            origin: "",
            destination: "",
            status: 1,
            errorMessage:"",
            errorSatus:false,
            documents:[],
            periodTypes:[],
            limitTypes:[],
            actions:[],
            countriesDetails:[]
        }
    },
     mounted() {
        this.amlOptions()

    },

    methods: {
        hideModal() {
            this.$parent.modal = false;
        },
        saveRule() {
            let vm  = this
            $('#createRule').html('<i class="fa fa-spinner fa-spin"></i> Please wait ...');
            $.post({
                url: store.state.rootUrl + 'v1/aml',
                type: "POST",
               
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+ vm.$cookies.get("accessToken")
                },
                data: JSON.stringify({
                    ruleName: vm.ruleName,
                    period: vm.period,
                    periodType: vm.periodType,
                    action: vm.action,
                    limit: vm.limit,
                    limitType: vm.limitType,
                    currency: vm.currency,
                    documentationRequired: vm.documentationRequired,
                    documentationType: vm.documentationType,
                    origin: vm.origin,
                    destination: vm.destination,
                    status: 1

                }),
                success: function (response, status, jQxhr) {
                    // console.log(JSON.stringify(response.data.data));
                    $('#createRule').text('Create Rule')
                    if (response.responseCode != 200) {
                        
                        vm.errorSatus = true;
                        vm.errorMessage = response.message;
                    } else {
                        
                        vm.hideModal()
                        vm.$parent.$refs.vuetable.refresh();
                    }

                },
                error: function (jQxhr, status, error) {
                    var errorMessage = jQxhr.responseJSON.errors[0];
                    var statustext = jQxhr.responseJSON.message;
                    vm.errorSatus = true;
                    $('#createRule').text('Create Rule')
                    if (!statustext) {
                        vm.errorMessage = "An error occurred. Try again later.";
                    } else {
                        vm.errorMessage = statustext + " " + errorMessage;
                    }
                }
            });
            
        },
        amlOptions(){
             let vm  = this
              $.get({
                url: store.state.rootUrl + 'v1/aml/select',
                type: "GET",
               
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+ vm.$cookies.get("accessToken")
                },
                data: {

                },
                success: function (response, status, jQxhr) {
                    // console.log(JSON.stringify(response.data.data));
                    if (response.responseCode != 200) {
                    
                        vm.errorSatus = true;
                        vm.errorMessage = response.message;
                    } else {
                        vm.documents = response.data.documents
                        vm.periodTypes = response.data.periodType
                        vm.limitTypes = response.data.limitType
                        vm.actions = response.data.action
                        vm.countriesDetails = response.data.countries

                    }

                },
                error: function (jQxhr, status, error) {
                    var errorMessage = jQxhr.responseJSON.errors[0];
                    var statustext = jQxhr.responseJSON.message;
                    vm.errorSatus = true;
                    if (!statustext) {
                        vm.errorMessage = "An error occurred. Try again later.";
                    } else {
                        vm.errorMessage = statustext + " " + errorMessage;
                    }
                }
            });
        }
    },
    
}
</script>
