<template>
<div class="fixed left-0 top-0 bottom-0 w-full z-1 bg-black bg-opacity-10 transition" id="aml-rules-add">
    <div class="absolute top-0 bottom-0 right-0 w-1/3 bg-white z-2 p-4 overflow-auto">
        <div class="block mb-4">
            <div class="text-lg">Add Account</div>
            <div class="text-xs text-gray-500">Account canbe either paybill or bank</div>
        </div>
        <form @submit.prevent="createAccount">
        <div class="block my-6">
            <label for="" class="text-sm mb-1 block">Account Name</label>
            <input class="text-xs border border-gray-300 px-3 py-2 w-full rounded-md focus:outline-none" v-model="accName" required/>
        </div>
         <div class="block my-6">
            <label for="" class="text-sm mb-1 block">Account Number</label>
            <input class="text-xs border border-gray-300 px-3 py-2 w-full rounded-md focus:outline-none" v-model="accNumber" required/>
        </div>
         <div class="block my-6">
            <label for="" class="text-sm mb-1 block">Top Amount</label>
            <input type="number" class="text-xs border border-gray-300 px-3 py-2 w-full rounded-md focus:outline-none" v-model="amount" required/>
        </div>
        <div class="block my-6">
            <label for="" class="text-sm mb-1 block">Currency</label>
            <select class="text-xs border border-gray-300 px-3 py-2 w-full block rounded-md focus:outline-none" v-model="currency" required>
                <option v-for="(country,index) in countriesDetails" :key="index" :value="country.currency">{{country.currency}}</option>
            </select>
        </div>
        <div class="block my-6">
            <label for="" class="text-sm mb-1 block">Account Type</label>
            <select class="text-xs border border-gray-300 px-3 py-2 w-full block rounded-md focus:outline-none" v-model="accType" required>
                <option v-for="(type,index) in accTypes" :key="index" :value="type.value">{{type.text}}</option>
            </select>
        </div>
       
        <div class="block mt-16">
            <button class="px-3 py-2 rounded-md border text-sm focus:outline-none text-gray-700 bg-gray-200 mr-4" @click="hideModal">Cancel</button>
            <button class="px-3 py-2 rounded-md border text-sm focus:outline-none text-white bg-green-500" type="submit" id="createRule">Create Account</button>
        </div>
        </form>
    </div>
</div>
</template>

<script>
import $ from "jquery";
import store from "../store";
export default {
    data: function () {
        return {
            accName: '',
            accNumber:'',
            amount:0.00,
            action: '',
            status: 1,
            currency:"",
            errorMessage:"",
            errorSatus:false,
            actions:[],
            countriesDetails:[],
            accType:'',
            accTypes:[{
                'text':'Bank',
                'value':'Bank'
            },{
                'text':'Paybill',
                'value':'Paybill'
            },
            ]
        }
    },
     mounted() {
        this.amlOptions()

    },

    methods: {
        hideModal() {
            this.$parent.modal = false;
        },
        createAccount() {
            let vm  = this
            $('#createRule').html('<i class="fa fa-spinner fa-spin"></i> Please wait ...');
            $.post({
                url: store.state.rootUrl + 'v1/partner/account/add',
                type: "POST",
               
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+ vm.$cookies.get("accessToken")
                },
                data: JSON.stringify({
                    accountName: vm.accName,
                    accountNumber: vm.accNumber,
                    topUpAmount: vm.amount,
                    currency: vm.currency,
                    accountType: vm.accType,

                }),
                success: function (response, status, jQxhr) {
                    // console.log(JSON.stringify(response.data.data));
                    $('#createRule').text('Create Account')
                    if (response.responseCode != 200) {
                        
                        vm.errorSatus = true;
                        vm.errorMessage = response.message;
                    } else {
                        
                        vm.hideModal()
                        vm.$parent.getReportSearch();
                    }

                },
                error: function (jQxhr, status, error) {
                    var errorMessage = jQxhr.responseJSON.errors[0];
                    var statustext = jQxhr.responseJSON.message;
                    vm.errorSatus = true;
                    $('#createRule').text('Create Account')
                    if (!statustext) {
                        vm.errorMessage = "An error occurred. Try again later.";
                    } else {
                        vm.errorMessage = statustext + " " + errorMessage;
                    }
                }
            });
            
        },
        amlOptions(){
             let vm  = this
              $.get({
                url: store.state.rootUrl + 'v1/aml/select',
                type: "GET",
               
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+ vm.$cookies.get("accessToken")
                },
                data: {

                },
                success: function (response, status, jQxhr) {
                    // console.log(JSON.stringify(response.data.data));
                    if (response.responseCode != 200) {
                    
                        vm.errorSatus = true;
                        vm.errorMessage = response.message;
                    } else {
                        vm.documents = response.data.documents
                        vm.periodTypes = response.data.periodType
                        vm.limitTypes = response.data.limitType
                        vm.actions = response.data.action
                        vm.countriesDetails = response.data.countries

                    }

                },
                error: function (jQxhr, status, error) {
                    var errorMessage = jQxhr.responseJSON.errors[0];
                    var statustext = jQxhr.responseJSON.message;
                    vm.errorSatus = true;
                    if (!statustext) {
                        vm.errorMessage = "An error occurred. Try again later.";
                    } else {
                        vm.errorMessage = statustext + " " + errorMessage;
                    }
                }
            });
        }
    },
    
}
</script>
