import Vue from 'vue';
import App from './App.vue';
import router from './router.js';
import store from './store.js';
import VueSweetalert2 from 'vue-sweetalert2';
import VCalendar from 'v-calendar';
import 'sweetalert2/dist/sweetalert2.min.css';
import "@/assets/scss/app.scss";

import VueCookies from 'vue-cookies'

Vue.config.productionTip = false

Vue.use(VueCookies)
Vue.use(VueSweetalert2);
Vue.use(VCalendar, {
  //componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});

Vue.$cookies.config('7d')

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
