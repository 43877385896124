<template>
<div class="fixed left-0 top-0 bottom-0 w-full z-1 bg-black bg-opacity-50 transition" id="ticket-details">
    <div class="absolute top-0 bottom-0 right-0 w-1/2 bg-white z-2 overflow-auto">
    <loader v-show="isLoading"></loader>
        <div class="flex px-4 py-2 border-b sticky top-0 bg-white items-center" v-show="!isLoading">
            <div class="flex-shrink py-2 px-4 mr-4 border-2 font-bold rounded-md text-lg items-center" :class="importance | ticketImportanceClass">{{importance}}</div>
            <div class="flex-grow block">
                <div class="text-xl font-medium">Issue #{{ticketID}} {{ticketsInfo}}</div>
                <div class="text-xs text-gray-500">Created on {{ticketDate | formatDate}} by {{ticketAssignBy}}</div>
            </div>
            <div class="flex-shrink cursor-pointer" @click="hideModal">
                <svg viewBox="0 0 18 18" width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h18v18H0Zm0 0h18v18H0Z"/><path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9Z"/></svg>
            </div>
        </div>

        <div class="block bg-green-100 m-4 rounded-md p-4" v-show="!isLoading">
            <div class="text-lg">{{cname}}</div>
            <div class="text-sm flex gap-6 text-gray-500 pb-4 mb-4 border-b border-gray-300">
                <div class="text-sm">{{cphone}}</div>
                <div class="text-sm">{{cemail}}</div>
                <div class="text-sm">{{ccountry}}</div>
            </div>
            <div class="text-xs text-gray-500">
               <span class="font-medium">Last login:</span> {{cUpdated | formatDateLast}}
            </div>
        </div>
         <!-- Notes -->
         <div class="relative p-4" v-show="!isLoading">
             <div class="flex w-full items-top">
                <div class="flex-grow">
                    <div class="text-lg">Issue details</div>
                    <div class="text-xs text-gray-400"></div>
                </div>
                <div class=""><div class="text-sm px-3 py-1 bg-yellow-200 rounded-full">{{type}}</div></div>
            </div>
             <div class="block my-4 my-2 pl-4 border-l-4 border-blue-400">
                <div class="text-md text-gray-600 mb-1">{{title}}</div>
                <div class="text-xs text-gray-400">{{description}}</div>
            </div>
            <div class="block my-4 my-2 pl-4 border-l-4 border-green-400" v-for="(resp,index) in responses" :key="index" v-show="responses.length != 0" >
                
                <div class="text-md text-gray-600 mb-1">{{resp.notes}}</div>
                <div class="text-xs text-gray-400">{{resp.created_at | formatDate}}</div>
            </div>

            <div class="block my-4 my-2 pl-4 border-l-4 border-red-400 text-xs 
            text-gray-500" v-for="(log,index) in logs" :key="index + 1">{{log.logs}} 
             {{log.created_at | formatDateLast}}</div>

            <div class="block my-4 py-2" v-show="!isClosed">
                <div class="text-sm text-gray-500 mb-1">Add Note</div>
                <textarea class="rounded-md h-24 border outline-none align-top p-3 w-full bg-blue-50 mb-2" v-model="note"></textarea>
                <button class="px-3 py-2 rounded-md border text-sm focus:outline-none text-white bg-green-500" @click="addNotes">Save Note</button>
            </div>
         </div>

        <div class="block my-4 hidden">
            <label for="" class="text-sm mb-1 block">Choose Country</label>
            <select class="text-xs border border-gray-300 px-3 py-2 w-full block rounded-md focus:outline-none">
                <option>All countries</option>
            </select>
        </div>

        <div class="absolute bottom-0 right-0 left-0 block bg-white z-10 border-t px-4 py-2">
            <button class="px-3 py-2 rounded-md border text-sm focus:outline-none text-gray-700 bg-gray-200" @click="hideModal">Close</button>
            <button class="px-3 py-2 rounded-md border text-sm focus:outline-none text-white bg-red-500 float-right ml-4" @click="closeTicket" v-show="!isClosed">Close Issue</button>
            <button class="px-3 py-2 rounded-md border text-sm focus:outline-none text-white bg-blue-500 float-right ml-4" @click="reOpenTicket" v-show="isClosed">Reopen Issue</button>
        </div>
    </div>
</div>
</template>

<script>
import $ from "jquery";
import store from "../store";
import moment from "moment";
import loader from "@/components/loader";
export default {
    data: function () {
        return {
            ticketID:"",
            ticketDate:"",
            ticketAssignBy:"",
            cname:"",
            cphone:"",
            cemail:"",
            ccountry:"",
            cUpdated:"",
            type:"",
            responses:[
            ],
            title:"",
            description:"",
            logs:[],
            isLoading: false,
            isClosed: false,
            importance:"",
            note:""
        }
    },
    computed: {
         ticketsInfo() {
            if (store.state.ticketData.length != 0) {
                this.fetchTicketsData(store.state.ticketData.id)
                if(store.state.ticketData.status == 3){
                    this.isClosed = true
                }
                //return store.state.ticketData
            }
        },
    },
     components: {
         loader
    },
    methods: {
        hideModal() {
            this.$parent.modalTickets = false;
        },
        fetchTicketsData(id){
            let vm  = this
            vm.isLoading = true
             $.get({
                    url: store.state.rootUrl + 'v1/issues/show/'+id,
                    type: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + vm.$cookies.get("accessToken")
                    },
                    success: function (response, status, jQxhr) {
                        vm.isLoading = false
                        if (response.responseCode != 200) {
                        } else {
                            vm.ticketID = response.data.id;
                            vm.ticketDate = response.data.created_at
                            vm.ticketAssignBy = response.data.assigned_by_user.name
                            vm.cname = response.data.raised_by_customer.names
                            vm.cphone = response.data.raised_by_customer.phone
                            vm.cemail = response.data.raised_by_customer.email
                            vm.ccountry =  response.data.raised_by_customer.country
                            vm.cUpdated = response.data.raised_by_customer.updated_at
                            vm.type = response.data.type
                            vm.title = response.data.title
                            vm.importance = response.data.importance
                            vm.description =  response.data.description
                            vm.responses = response.data.issue_response
                            vm.logs = response.data.support_issue_log
                        }
                    },
                    error: function (jQxhr, status, error) {
                        vm.isLoading = true
                        var errorMessage = jQxhr.responseJSON.errors[0];
                        var statustext = jQxhr.responseJSON.message;
                    }
                });
        },

        reOpenTicket(){
            let vm  = this
            vm.$swal({
                title: 'Are you sure?',
                text: 'You want to reopen this Ticket',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, Proceed & Open it!',
                cancelButtonText: 'Cancel',
                showCloseButton: true,
                showLoaderOnConfirm: true,
                confirmButtonColor: '#41b882',
                cancelButtonColor: '#ff7674'
                }).then((result) => {
                if(result.value) {
                    $.post({
                        url: store.state.rootUrl + "v1/issues/action",
                        type: "POST",
                         headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + vm.$cookies.get("accessToken")
                    },
                        data: JSON.stringify({
                            ticketId:store.state.ticketData.id,
                            status: 2
                        }),
                        success: function (response, status, jQxhr) {
                            
                            vm.$swal('Sent', 'Successfully reopen ticket', 'success')
                            store.state.ticketData.status = 2
                            vm.$parent.getAllTickets(1)
                            vm.hideModal()
                            
                        },
                        error: function (jQxhr, status, error) {
                             vm.$swal('Failed', 'Failed to reopen ticket', 'error')
                             vm.hideModal()
                        }
                    });
                    
                } else {
                    vm.$swal('Cancelled', 'Cancel to reopen ticket', 'info')
                }
            })
        },
        closeTicket(){
            let vm  = this
            vm.$swal({
                title: 'Are you sure?',
                text: 'You want to close this Ticket',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, Proceed & Close it!',
                cancelButtonText: 'Cancel',
                showCloseButton: true,
                showLoaderOnConfirm: true,
                confirmButtonColor: '#41b882',
                cancelButtonColor: '#ff7674'
                }).then((result) => {
                if(result.value) {
                   $.post({
                        url: store.state.rootUrl + "v1/issues/action",
                        type: "POST",
                         headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + vm.$cookies.get("accessToken")
                    },
                        data: JSON.stringify({
                            ticketId:store.state.ticketData.id,
                            status: 3
                        }),
                        success: function (response, status, jQxhr) {
                            
                            vm.$swal('Sent', 'Successfully closed ticket', 'success')
                            store.state.ticketData.status = 3
                            vm.$parent.getAllTickets(1)
                            vm.hideModal()
                            
                        },
                        error: function (jQxhr, status, error) {
                             vm.$swal('Failed', 'Failed to closed ticket', 'error')
                             vm.hideModal()
                        }
                    });
                    
                } else {
                    vm.$swal('Cancelled', 'Cancel ticket resent', 'info')
                }
            })
        },
        addNotes(){
            let vm  = this
            vm.$swal({
                title: 'Are you sure?',
                text: 'You want to note to this Ticket',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, Proceed & Add',
                cancelButtonText: 'Cancel',
                showCloseButton: true,
                showLoaderOnConfirm: true,
                confirmButtonColor: '#41b882',
                cancelButtonColor: '#ff7674'
                }).then((result) => {
                if(result.value) {
                   $.post({
                        url: store.state.rootUrl + "v1/issues/reponse/thread",
                        type: "POST",
                         headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + vm.$cookies.get("accessToken")
                    },
                        data: JSON.stringify({
                            ticketId:store.state.ticketData.id,
                            notes: vm.note,
                            respondedBy:vm.$cookies.get("userID"),

                        }),
                        success: function (response, status, jQxhr) {
                            
                            vm.$swal('Sent', 'Successfully updated ticket', 'success')
                            vm.fetchTicketsData(store.state.ticketData.id)
                            vm.$parent.getAllTickets()
                            
                        },
                        error: function (jQxhr, status, error) {
                             vm.$swal('Failed', 'Failed to add note ticket', 'error')
                             
                        }
                    });
                    
                } else {
                    vm.$swal('Cancelled', 'Cancel to add ticket note', 'info')
                }
            })
        }
    },
    filters: {
        formatDate(value) {
            if (!value) return "";
            return moment(value).format("DD MMM YYYY");
        },
        formatDateLast: function (value) {
            if (!value) return "";
            return moment(value).fromNow();
        },
        ticketImportanceClass: function (value) {
            if(value == 'High'){
                return "border-red-400 text-red-400 bg-red-100 "
            }
            else if(value == 'Low'){
                return "border-blue-400 text-blue-400 bg-blue-100 "
            }
            else {
                return "border-yellow-400 text-yellow-400 bg-yellow-100 "
            }
        }
    }
}
</script>
