<template>
<router-view></router-view>
</template>

<script>
export default {
    mounted() {
        let vm = this;
        if (vm.$cookies.get("roleID") == 2) {
            vm.$router.push({
                name: 'tickets'
            })
        }
    },
}
</script>
