<template>
<div class="fixed left-0 top-0 bottom-0 w-full z-1 bg-black bg-opacity-10 transition" id="aml-rules-add">
    <div class="absolute top-0 bottom-0 right-0 w-1/3 bg-white z-2 p-4 overflow-auto">
        <div class="block mb-4">
            <div class="text-lg">Add Account</div>
            <div class="text-xs text-gray-500">Account canbe either paybill or bank</div>
            <div v-show="errorSatus" class="text-red-500 px-2 py-1 rounded-sm bg-red-100 text-xs">
                {{errorMessage}}
            </div>
        </div>
        <form @submit.prevent="updateAccount">
            <div class="block my-6">
                <label for="" class="text-sm mb-1 block">Account Name</label>
                <input class="text-xs border border-gray-300 px-3 py-2 w-full rounded-md focus:outline-none" v-model="accName" disabled />
            </div>
            <div class="block my-6">
                <label for="" class="text-sm mb-1 block">Account Number</label>
                <input class="text-xs border border-gray-300 px-3 py-2 w-full rounded-md focus:outline-none" v-model="accNumber" disabled />
            </div>
            <div class="block my-6">
                <label for="" class="text-sm mb-1 block">Top Amount</label>
                <input type="number" class="text-xs border border-gray-300 px-3 py-2 w-full rounded-md focus:outline-none" v-model="amount" required />
            </div>

            <div class="block mt-16">
                <button class="px-3 py-2 rounded-md border text-sm focus:outline-none text-gray-700 bg-gray-200 mr-4" type="button" @click="hideModal">Cancel</button>
                <button class="px-3 py-2 rounded-md border text-sm focus:outline-none text-white bg-green-500" type="submit" id="createRule">Update Balance</button>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import $ from "jquery";
import store from "../store";
export default {
    data: function () {
        return {
            accName: '',
            accNumber: '',
            amount: 0.00,
            action: '',
            status: 1,
            currency:"",
            errorMessage: "",
            errorSatus: false,
            actions: [],
            countriesDetails: [],
            accType: '',
            accTypes: [{
                'text': 'Bank',
                'value': 'Bank'
            }, {
                'text': 'Paybill',
                'value': 'Paybill'
            }, ]
        }
    },
    computed: {
    account () {
      return store.state.accountSelected
      // Or return basket.getters.fruitsCount
      // (depends on your design decisions).
    }
  },
    watch: { 
        account (newCount, oldCount) {
            this.accName = newCount.accountName
            this.accNumber = newCount.accountNumber
            this.amount =  newCount.topUpAmount
            this.currency = newCount.currency
      // Our fancy notification (2).
      console.log(`We have `+JSON.stringify(newCount))
    }
    },

    methods: {
        hideModal() {
            this.$parent.showAppAcc = false;
        },
        updateAccount() {
            let vm = this
            $('#updateAccount').html('<i class="fa fa-spinner fa-spin"></i> Please wait ...');
            $.post({
                url: store.state.rootUrl + 'v1/partner/account/balance',
                type: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + vm.$cookies.get("accessToken")
                },
                data: JSON.stringify({
                    accountID:store.state.accountSelected.id,
                    amount: vm.amount,
                    currency: vm.currency

                }),
                success: function (response) {

                    $('#updateAccount').text('Update Account')
                    if (response.responseCode != 200) {
                        vm.errorSatus = true;
                        vm.errorMessage = response.message;
                    } else {

                        vm.hideModal()
                        vm.$parent.getReportSearch();
                    }

                },
                error: function (jQxhr, status, error) {
                    var errorMessage = jQxhr.responseJSON.errors[0];
                    var statustext = jQxhr.responseJSON.message;
                    vm.errorSatus = true;
                    $('#createRule').text('Create Account')
                    if (!statustext) {
                        vm.errorMessage = "An error occurred. Try again later.";
                    } else {
                        vm.errorMessage = statustext + " " + errorMessage;
                    }
                }
            });
        },
    },

}
</script>
