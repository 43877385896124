<template>
    <div class="relative p-4">
        <div class="flex w-full pb-4 items-center">
            <div class="flex-shrink">
                <div class="text-lg">Forex Rates</div>
                <div class="text-xs text-gray-500">123 users</div>
            </div>
            <div class="flex-grow text-right">
                <div class="inline-block mr-4"><input class="px-3 py-2 rounded-md border text-xs focus:outline-none text-gray-700 bg-white focus:border-gray-400" placeholder="Search" v-model="search" @keyup="searchRecords"></div>
                <button class="inline-block focus:outline-none px-3 py-2 rounded-md bg-green-500 text-xs text-white  items-center mr-4 border border-gray-300"><span>Add New Rate</span></button>
            </div>
        </div>
        <div class="block">
            <table class="w-full rounded-md border text-xs">
                <thead>
                <tr class="bg-gray-100  text-left">
                    <th class="py-2 px-2 border-r w-1 text-center">#</th>
                    <th class="py-2 px-2 border-r">Currency</th>
                    <th class="py-2 px-2 border-r text-right">Market Exchange Rate</th>
                    <th class="py-2 px-2 border-r text-right">Helasend Exchange Rate</th>
                    <th class="py-2 px-2 border-r text-right">Helasend Margin</th>
                    <th class="py-2 px-2 border-r">Percentage</th>
                </tr>
                </thead>
                <tbody>
                 <loader v-show="isLoading"></loader>
                <tr class="border-b" v-for="(forex,index) in forexs" :key="index" v-show="!isLoading">
                    <td class="py-2 px-2 border-r w-1 text-center">{{index+1}}</td>
                    <td class="py-2 px-2 border-r">{{forex.currency}}</td>
                    <td class="py-2 px-2 border-r text-right">{{forex.marketExchangerate}}</td>
                    <td class="py-2 px-2 border-r text-right">{{forex.helesendExchangerate}}</td>
                    <td class="py-2 px-2 border-r text-right">{{forex.helesendExchangerateMargin}}</td>
                    <td class="py-2 px-2 border-r">{{forex.fxPercentage}}%</td>
                </tr>
                </tbody>
            </table>
            <button class="px-3 py-1 bg-green-200 text-green-900 rounded-md mr-2" v-show="!isLoading" @click="fetchForexs()">Load More</button>
        </div>
    </div>
</template>
<script>
import $ from 'jquery'
import store from "../store";
import moment from "moment";
import loader from "@/components/loader";
//you need to import the CSS manually

export default {
    data: function () {
        return {
            forexs: [],
            search:"",
            sort:"desc",
            page:0,
            total:0,
            limit:10,
            errorStatus:false,
            errorMessage:"",
            dateFormat: "dd-mm-yyyy",
            isLoading:false,
            startDate:"",
            endDate:""
        }
    },
     components: {
         loader,
    },
    mounted() {
       this.endDate =  moment().format("YYYY-MM-DD")
        this.startDate = moment().subtract(7,'d').format('YYYY-MM-DD');
        this.fetchForexs()

    },
    methods: {
        searchRecords() {
            let vm = this
            vm.forexs = []
            vm.total = 0
            vm.page = 0
            vm.fetchForexs()
        },
        fetchForexs() {
            let vm = this
            if(vm.total === 0 || vm.total >= ((vm.page - 1) * vm.limit)){
            vm.isLoading = true
            vm.page++
            $.get({
                url: store.state.rootUrl + 'v1/finance/exchangerate',
                type: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+ vm.$cookies.get("accessToken")
                },
                data: {
                    search: vm.search,
                    sort: vm.sort,
                    page: vm.page,
                    limit: vm.limit,
                    startDate: vm.startDate,
                    endDate: vm.endDate,
                    status:10
                },
                success: function (response, status, jQxhr) {
                    vm.isLoading = false
                    if (response.responseCode != 200) {
                        vm.errorStatus = true;
                        vm.errorMessage = response.message;
                    } else {
                         var list_c = response.data;
                         for (var i = 0; i < list_c.length; i++) {
                            vm.forexs.push(list_c[i])
                         }
                        vm.total = response.total
                    }
                },
                error: function (jQxhr, status, error) {
                    var errorMessage = jQxhr.responseJSON.errors[0];
                    var statustext = jQxhr.responseJSON.message;
                    vm.errorStatus = true;
                    vm.isLoading = false
                    $('#login').text('login')
                    if (!statustext) {
                        vm.errorMessage = "An error occurred. Try again later.";
                    } else {
                        vm.errorMessage = statustext + " " + errorMessage;
                    }
                }
            });

        }
        }
    },
    filters: {
        transactionsStatus(value){
            if(value == 1){
                return "Delivered"
            }
            else if(value == 2) {
                return "On Hold"
            }
            else if(value == 3) {
                return "Rejected"
            }
            else {
                return "Pending"
            }
        },
        transClassStatus(value){
             if(value == '1'){
                return "bg-green-200 text-yellow-900"
            }
            else if(value == '2'){
                return "bg-yellow-200 text-green-900"
            }
            else if(value == '3') {
                return "bg-red-200 text-red-900"
            }
            else {
               return "bg-gray-200 text-gray-900"
            }
        },
        formatDate(value){
            if (!value) return "";
            return moment(value).format("DD MMM YYYY");
        },
    }
}
</script>
