<template>
<div class="relative p-4">
    <div class="flex w-full pb-4 items-center">
        <div class="flex-shrink">
            <div class="text-lg">Blocked Users</div>
        </div>
        <div class="flex-grow text-right">
            <div class="inline-block mr-2"><input class="px-3 py-2 rounded-md border text-xs focus:outline-none text-gray-700 bg-white focus:border-gray-400" placeholder="Search" v-model="search_match" @keyup="searchRecord"></div>
            <input type="date" class="text-xs border px-3 py-2 rounded-md ml-2" placeholder="Start Date" v-model="startDate" />
            <input type="date" class="text-xs border px-3 py-2 rounded-md ml-2" placeholder="End Date" v-model="endDate" />
            <button class="px-3 py-2 rounded-md border text-xs focus:outline-none text-white bg-blue-500 ml-2" @click="searchRecord">Go</button>
        </div>
    </div>
    <div class="block">
    <vuetable ref="vuetable" :css="cssTable" :http-options="httpOptions" :fields="columns" :sort-order="sortOrder" track-by="id" :append-params="moreParams" :per-page="limit" @vuetable:pagination-data="onPaginationData" @vuetable:loading="onLoading" @vuetable:loaded="onLoaded">
                 <div slot="approved" slot-scope="props">
                        <div :class="props.rowData.approved | amlClassStatus"><strong>{{props.rowData.approved | amlStatus}}</strong></div>
                    </div>
                    <div slot="verified" slot-scope="props">
                        <div :class="props.rowData.verified | verifiedClass"><strong>{{props.rowData.verified | statusVerified}}</strong></div>
                    </div>
                      
                    <div slot="source" slot-scope="props">
                        <div><small>From {{props.rowData.origin }}  to {{ props.rowData.destination}}</small></div>
                    </div>
                     <div slot="period" slot-scope="props">
                        <div>{{ props.rowData.periodValue}} {{props.rowData.periodType }}</div>
                    </div>
                    <div slot="documentationRequired" slot-scope="props">
                        <div :class="props.rowData.documentationRequired | amlClassDocumentStatus"><strong>{{props.rowData.documentationRequired | amlDocumentStatus}}</strong></div>
                    </div>
                    
                <div class="table-button-container" slot="action" slot-scope="props">
                    <button v-show="props.rowData.verified != '1'" :id="'action-activate'+props.rowData.id" class="text-green-500" @click="updateAccountState(props.rowData.id,'enable')"><i class="fa fa-check" aria-hidden="true"></i> Enable </button>
                    <button v-show="props.rowData.verified == '1'" :id="'action-disable'+props.rowData.id" class="text-red-500" @click="updateAccountState(props.rowData.id,'disable')"><i class="fa fa-ban" aria-hidden="true"></i> Disable </button>

                </div>

            </vuetable>
            <div class="block">
                <vuetable-pagination-info :css="paginationInfo" ref="paginationInfo"></vuetable-pagination-info>
                <vuetable-pagination ref="pagination" :css="pagination" @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
            </div>
    </div>

</div>
</template>
<style scoped>
@import "https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css";
@import "https://cdn.datatables.net/1.10.20/css/jquery.dataTables.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css";
</style>

<script>
import $ from 'jquery'
import store from "../store";
import moment from "moment";
import loader from "@/components/loader";
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
//you need to import the CSS manually

export default {
    data: function () {
        return {
            httpOptions: {
                baseURL: 'https://api.helasend.com/api/v1/customers',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + this.$cookies.get("accessToken")
                }
            },
            cssTable: {
                tableWrapper: "",
                tableHeaderClass: "mb-0",
                tableBodyClass: "mb-0",
                loadingClass: 'loading',
                ascendingIcon: "fa fa-chevron-up",
                descendingIcon: "fa fa-chevron-down",
                ascendingIcon: 'green chevron up icon',
                descendingIcon: 'green chevron down icon',
                sortableIcon: "",
                detailRowClass: "vuetable-detail-row",
                handleIcon: "fa-bars text-secondary",
                renderIcon(classes, options) {
                    return `<i class="${classes.join(" ")}" ${options}></span>`;
                }
            },
             pagination: {
                wrapperClass: 'ui right floated pagination menu',
                activeClass: 'active',
                disabledClass: 'disabled',
                pageClass: 'item',
                linkClass: 'icon item',
                paginationClass: 'ui bottom attached segment grid',
                paginationInfoClass: 'left floated left aligned six wide column',
                dropdownClass: 'ui search dropdown',
                icons: {
                    first: 'angle double left icon',
                    prev: 'left chevron icon',
                    next: 'right chevron icon',
                    last: 'angle double right icon',
                }
            },
            paginationInfo: {
                infoClass: 'left floated left aligned six wide column',
            },
            columns: [{
                    name: "names",
                    title: 'Name',
                    sortField: 'names',
                },
                {
                    name: "phone",
                    title: 'Contacts',
                    sortField: 'phone',
                },
                'country',
                'verified',
                {
                    name: "approved",
                    title: 'AML',
                    sortField: 'approved',
                },
                {
                    name: "amlScore",
                    title: 'AML Score',
                },
                {
                    name: 'updated_at',
                    title: 'Date',
                    sortField: 'updated_at',
                    titleClass: '',
                    dataClass: ''

                },
                'action'

            ],
             sortOrder: [{
                field: 'created_at',
                direction: 'desc'
            }],
            is_partial_resulting: false,
            moreParams: {
                start: '',
                filter: '',
                end: '',
                category:'banned'
            },
            users: [],
            search_match: "",
            sort: "desc",
            page: 1,
            total: 0,
            limit: 10,
            errorStatus: false,
            errorMessage: "",
            dateFormat: "dd-mm-yyyy",
            isLoading: false,
            amlClass: "",
            startDate: "",
            endDate: "",
            totalPages: 0
        }
    },
    components: {
        loader,Vuetable,VuetablePagination,VuetablePaginationInfo
    },
    mounted() {
        this.endDate = moment().format("YYYY-MM-DD")
        this.startDate = moment().subtract(7, 'd').format('YYYY-MM-DD');
      

    },
    methods: {
        onChangePage(pageOfItems) {
            let vm = this
            vm.page = pageOfItems;
            vm.users = []
            vm.fetchUsersData(this.page)
        },
        updateAccountState(userID, state) {
            let vm = this
            $('#action-' + state + "-" + userID).html('<i class="fa fa-spinner fa-spin"></i> Please wait ...');
            $.post({
                url: store.state.rootUrl + 'v1/customers/action',
                type: "POST",

                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + vm.$cookies.get("accessToken")
                },
                data: JSON.stringify({
                    userID: userID,
                    action: state,

                }),
                success: function (response, status, jQxhr) {
                    // console.log(JSON.stringify(response.data.data));

                    if (response.responseCode != 200) {
                        if (state)
                            $('#action-' + state + "-" + userID).text(state)
                        vm.errorStatus = true;
                        vm.errorMessage = response.message;
                    } else {
                        vm.$refs.vuetable.refresh();
                    }

                },
                error: function (jQxhr, status, error) {
                    var errorMessage = jQxhr.responseJSON.errors[0];
                    var statustext = jQxhr.responseJSON.message;
                    vm.errorStatus = true;
                    $('#action-' + state + "-" + userID).text(state)
                    if (!statustext) {
                        vm.errorMessage = "An error occurred. Try again later.";
                    } else {
                        vm.errorMessage = statustext + " " + errorMessage;
                    }
                }
            });
        },
        createdAt: function (value) {
            if (!value) {
                return '-';
            } else {
                return moment(value).format('h:mm a,DD MMM YYYY');
            }

        },

        searchRecord: function () {
            this.moreParams.filter = this.search_match;
            this.moreParams.start = this.startDate;
            this.moreParams.end = this.endDate;
            this.$refs.vuetable.refresh();
        },

        dataManager(sortOrder, pagination) {
            if (this.data.length < 1) return;

            let local = this.data;

            // sortOrder can be empty, so we have to check for that as well
            if (sortOrder.length > 0) {
                console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
                local = _.orderBy(
                    local,
                    sortOrder[0].sortField,
                    sortOrder[0].direction
                );
            }

            pagination = this.$refs.vuetable.makePagination(
                local.length,
                this.perPage
            );
            console.log('pagination:', pagination)
            let from = pagination.from - 1;
            let to = from + this.perPage;

            return {
                pagination: pagination,
                data: _.slice(local, from, to)
            };
        },

        onPaginationData(paginationData) {
            this.$refs.paginationInfo.setPaginationData(paginationData)
            this.$refs.pagination.setPaginationData(paginationData)
        },

        onChangePage(page) {
            this.$refs.vuetable.changePage(page)
        },

        onLoading() {
            this.loading = true
            console.log('loading... show your spinner here')
        },
        onLoaded() {
            this.loading = false
            console.log('loaded! .. hide your spinner here');

        },
    },
    filters: {
        amlStatus(value) {
            if (value == 1) {
                return "Active"
            } else {
                return "NOT Active"
            }
        },
        amlClassStatus(value) {
            if (value == '1') {
                return "text-green-500"
            } else {
                return "text-red-500"
            }
        },
        statusVerified(value) {
            if (value == 1) {
                return "Verified"
            } else if (value == 17) {
                return "Banned"
            } else {
                return "Not Verified"
            }
        },
        verifiedClass(value) {
            if (value == 1) {
                return "text-green-500"
            } else if (value == 17) {
                return "text-red-500"
            } else {
                return "text-yellow-500"
            }
        },
        formatDate(value) {
            if (!value) return "";
            return moment(value).format("DD MMM YY hh:mm");
        },
    }
}
</script>
