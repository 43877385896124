import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    rootUrl :  "https://api.helasend.com/api/", 
    userData : [],
    ticketData: [],
    transactionData: [],
    accountSelected:[],
    country:[],
    selectedTransactions:[]
  },
  mutations: {
    setSelectedTransactions(state,data){
      state.selectedTransactions =  data
    },
    setCountryData(state,data){
      state.country = data
    },
    setAccount(state,data){
      state.accountSelected = data
    },
    setUserInformation(state,data){
      state.userData = data
    },
    setTicketInformation(state,data){
      state.ticketData = data
    },
    setTransactionsInformation(state,data){
      state.transactionData = data
    }
  },
  actions: {
  },
  modules: {
  }
})
