<template>
<div class="fixed left-0 top-0 bottom-0 w-full z-1 bg-black bg-opacity-10 transition" id="aml-rules-add">
    <div class="absolute top-0 bottom-0 right-0 w-1/3 bg-white z-2 p-4 overflow-auto">
        <div class="block mb-4">
            <div class="text-lg">Reset System User</div>
            <div v-show="errorSatus" class="text-red-500 px-2 py-1 rounded-sm bg-red-100 text-xs">
                {{errorMessage}}
            </div>
        </div>
        <form @submit.prevent="updateAccount">
            <div class="block my-6">
                <label for="" class="text-sm mb-1 block">Name</label>
                <input class="text-xs border border-gray-300 px-3 py-2 w-full rounded-md focus:outline-none" v-model="name" disabled />
            </div>
            <div class="block my-6">
                <label for="" class="text-sm mb-1 block">Email</label>
                <input class="text-xs border border-gray-300 px-3 py-2 w-full rounded-md focus:outline-none" v-model="email" disabled />
            </div>
           

            <div class="block mt-16">
                <button class="px-3 py-2 rounded-md border text-sm focus:outline-none text-gray-700 bg-gray-200 mr-4" type="button" @click="hideModal">Cancel</button>
                <button class="px-3 py-2 rounded-md border text-sm focus:outline-none text-white bg-green-500" type="submit" id="updateAccount">Reset Password</button>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import $ from "jquery";
import store from "../store";
export default {
    data: function () {
        return {
            name: '',
            email: '',
            password: '',
            type:'',
            errorMessage: "",
            errorSatus: false,
            userID:""
        }
    },
    computed: {
         account() {
            return store.state.userData
        }
    },
    watch: {
        account(newCount, oldCount) {
            console.log(`We have ` + JSON.stringify(newCount))
            this.name = newCount.name
            this.email = newCount.email
            this.userID = newCount.id
            
        }
    },

    methods: {
        hideModal() {
            this.$parent.editModal = false;
        },
        updateAccount() {
            let vm = this
            $('#updateAccount').html('<i class="fa fa-spinner fa-spin"></i> Please wait ...');
            $.post({
                url: store.state.rootUrl + 'v1/customer/password/reset',
                type: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + vm.$cookies.get("accessToken")
                },
                data: JSON.stringify({
                    userId: vm.userID,

                }),
                success: function (response) {

                    $('#updateAccount').text('Approve Account')
                    if (response.statusCode != 200) {
                        vm.errorSatus = true;
                        vm.errorMessage = response.message;
                    } else {
                        vm.hideModal()
                        vm.$parent.$refs.vuetable.refresh();
                        vm.$swal('Sent',response.message , 'success')
                    }
                },
                error: function (jQxhr, status, error) {
                    var errorMessage = jQxhr.responseJSON.errors[0];
                    var statustext = jQxhr.responseJSON.message;
                    vm.errorSatus = true;
                    $('#updateAccount').text('Approve Account')
                    if (!statustext) {
                        vm.errorMessage = "An error occurred. Try again later.";
                    } else {
                        vm.errorMessage = statustext + " " + errorMessage;
                    }
                }
            });
        },
    },

}
</script>
