<template>
<div class="fixed left-0 top-0 bottom-0 w-full z-1 bg-black bg-opacity-10 transition" id="aml-rules-add">
    <div class="absolute top-0 bottom-0 right-0 w-1/3 bg-white z-2 p-4 overflow-auto">
        <div class="block mb-4">
            <div class="text-lg">Add Region</div>
            <div class="text-xs text-gray-500">Add a country to activate payouts to.</div>
        </div>
        <div class="block my-4">
            <label for="" class="text-sm mb-1 block">Choose Country</label>
            <select class="text-xs border border-gray-300 px-3 py-2 w-full block rounded-md focus:outline-none">
                <option>All countries</option>
            </select>
        </div>

        <div class="block my-4">
            <label for="" class="text-sm mb-1 block">Activate Payout Methods</label>
            <div class="p-2 bg-gray-100 rounded-sm mb-4">
                <div class="text-sm text-gray-600 mb-2">Mobile Money</div>
                <div class="grid grid-cols-2">
                    <label class="w-2/3 whitespace-nowrap block text-sm"><input type="checkbox" /> Tigo</label>
                    <label class="w-2/3 whitespace-nowrap block text-sm"><input type="checkbox" /> Airtel Money</label>
                    <label class="w-2/3 whitespace-nowrap block text-sm"><input type="checkbox" /> MPESA</label>
                    <label class="w-2/3 whitespace-nowrap block text-sm"><input type="checkbox" /> VOOMA</label>
                </div>
            </div>

            <div class="p-2 bg-gray-100 rounded-sm mb-4">
                <div class="text-sm text-gray-600 mb-2">Banks</div>
                <div class="grid grid-cols-2">
                    <label class="w-2/3 whitespace-nowrap block text-sm"><input type="checkbox" /> KCB</label>
                    <label class="w-2/3 whitespace-nowrap block text-sm"><input type="checkbox" /> Equity Bank</label>
                    <label class="w-2/3 whitespace-nowrap block text-sm"><input type="checkbox" /> NCBA Bank</label>
                    <label class="w-2/3 whitespace-nowrap block text-sm"><input type="checkbox" /> Transnational Bank</label>
                    <label class="w-2/3 whitespace-nowrap block text-sm"><input type="checkbox" /> Mwananchi Credit Bank</label>
                    <label class="w-2/3 whitespace-nowrap block text-sm"><input type="checkbox" /> I&M Bank</label>
                    <label class="w-2/3 whitespace-nowrap block text-sm"><input type="checkbox" /> Union Pay</label>
                </div>
            </div>
            <div class="p-2 bg-gray-100 rounded-sm mb-4">
                <div class="text-sm text-gray-600 mb-2">Cards & Online</div>
                <div class="grid grid-cols-2">
                    <label class="w-2/3 whitespace-nowrap block text-sm"><input type="checkbox" /> Mastercard</label>
                    <label class="w-2/3 whitespace-nowrap block text-sm"><input type="checkbox" /> VISA</label>
                    <label class="w-2/3 whitespace-nowrap block text-sm"><input type="checkbox" /> Union Pay</label>
                    <label class="w-2/3 whitespace-nowrap block text-sm"><input type="checkbox" /> Paypal</label>
                </div>
            </div>

        </div>
        <div class="block my-4">
            <label for="" class="text-sm mb-1 block">Amount Limits</label>
            <div class="flex gap-3">
                <input class="text-xs border border-gray-300 px-3 py-2 w-full rounded-md focus:outline-none" type="number" placeholder="limit" />
                <select class="text-xs border border-gray-300 px-3 py-2 w-full block rounded-md focus:outline-none">
                    <option>Limit type</option>
                    <option value="Value of transaction">Value of transaction</option>
                </select>
                <select class="text-xs border border-gray-300 px-3 py-2 w-full block rounded-md focus:outline-none">
                    <option value="USD">USD</option>
                    <option value="KES">KES</option>
                </select>
            </div>
        </div>
        <div class="block mt-16">
            <button class="px-3 py-2 rounded-md border text-sm focus:outline-none text-gray-700 bg-gray-200 mr-4" @click="hideModal">Cancel</button>
            <button class="px-3 py-2 rounded-md border text-sm focus:outline-none text-white bg-green-500" id="add-region">Add Region</button>
        </div>
    </div>
</div>
</template>

<script>
import $ from "jquery";
import store from "../store";
export default {
    data: function () {
        return {
            ruleName: '',
            period: '',
            periodType: false,
            action: '',
            limit: 1,
            limitType: "",
            currency: "",
            documentationRequired: "",
            documentationType: "",
            origin: "",
            destination: "",
            status: 1,
            errorMessage:"",
            errorSatus:false
        }
    },
    methods: {
        hideModal() {
            this.$parent.modal = false;
        }
    },
    
}
</script>
