<template>
    <div class="absolute left-0 top-0 w-full h-full">
        <div class="relative h-full w-full">
            <div class="absolute w-24 left-0 top-0 h-full px-3 py-4 bg-green-800 text-center overflow-auto pb-24">
                <ul class="list-none m-0 p-0 text-center">
                    <li v-show="isAdmin || isFinance" class="block px-2 py-2 rounded-md mb-2 opacity-75" :class="{'bg-white bg-opacity-20 opacity-100': $route.name == 'dashboard'}"><router-link :to="{name: 'dashboard'}"  class="text-white text-xl"><span style="font-size: 36px;" class="material-icons">space_dashboard</span><div class="text-xs">Summary</div></router-link></li>
                    <li class="block px-2 py-2 opacity-75 rounded-md mb-2" :class="{'bg-white bg-opacity-20 opacity-100': $route.name == 'tickets' || $route.name == 'tickets-search' || $route.name == 'tickets-unresolved'}"><router-link :to="{name: 'tickets-search'}" class="text-white text-xl"><span style="font-size: 36px;" class="material-icons">checklist</span><div class="text-xs">Support</div></router-link></li>
                    <li class="p-4 block"><hr class="opacity-50" /></li> 
                    <li v-show="isAdmin || isFinance" class="block px-2 py-2 opacity-75 rounded-md mb-2" :class="{'bg-white bg-opacity-20 opacity-100': $route.name == 'transactions' || $route.name == 'pending-transactions' || $route.name == 'rejected-transactions' || $route.name == 'checked-transactions'}"><router-link :to="{name: 'transactions'}" class="text-white text-xl"><span style="font-size: 36px;" class="material-icons">receipt_long</span><div class="text-xs">Txs</div></router-link></li>
                    <li v-show="isAdmin || isFinance" class="block px-2 py-2 opacity-75 rounded-md mb-2" :class="{'bg-white bg-opacity-20 opacity-100': $route.name == 'users' || $route.name == 'blocked-users'}"><router-link :to="{name: 'users'}" class="text-white text-xl"><span style="font-size: 46px;" class="material-icons">group</span><div class="text-xs">Users</div></router-link></li>
                    <li v-show="isAdmin || isFinance" class="block px-2 py-2 opacity-75 rounded-md mb-2" :class="{'bg-white bg-opacity-20 opacity-100': $route.name == 'forex' || $route.name == 'accounts' || $route.name == 'finance' || $route.name == 'aml-rules' || $route.name == 'countries'}"><router-link :to="{name: 'forex'}" class="text-white text-xl"><span style="font-size: 36px;" class="material-icons">calculate</span><div class="text-xs">Internal</div></router-link></li>
                    <li v-show="isAdmin" class="p-4 block"><hr class="opacity-50" /></li> 
                    <li v-show="isAdmin" class="block px-2 py-2 opacity-75 rounded-md mb-2" :class="{'bg-white bg-opacity-20 opacity-100': $route.name == 'view-system-user'}"><router-link :to="{name: 'view-system-user'}" class="text-white text-xl"><span style="font-size: 36px;" class="material-icons">settings</span><div class="text-xs">Settings</div></router-link></li>
                </ul>
                <div class="absolute bottom-0 w-full border-t border-green-700 left-0 m-auto bottom-0 block px-4 py-2 mb-2 cursor-pointer bg-green-800"><div @click="logout" class="text-white text-xl"><span style="font-size: 36px;" class="material-icons text-opacity-75 text-white">power_settings_new</span><div class="text-xs">Logout</div></div></div>
            </div>
            <div class="absolute md:left-24 left-0 right-0 h-full grid grid-cols-12">
                <div class="col-span-2 h-full top-0 p-4 bg-white border-r overflow-auto" v-show="$route.name != 'dashboard'">
                    <div class="" v-if="$route.name == 'dashboard'">
                        <div class="text-lg mb-4">Summary</div>
                        <ul class="list-none text-sm">
                            <li class="block mb-1"><router-link :to="{name: 'dashboard'}" class="block px-3 py-2 rounded-md hover:bg-gray-300 text-gray-600" :class="{'bg-gray-300': $route.name == 'dashboard'}">General Report</router-link></li>
                        </ul>
                    </div>
                    <div class="" v-if="$route.name == 'transactions' || $route.name == 'pending-transactions' || $route.name == 'rejected-transactions' || $route.name == 'checked-transactions' || $route.name == 'success-transactions' || $route.name =='refunded-transactions'">
                        <div class="text-lg mb-4">Transactions</div>
                        <ul class="list-none text-sm">
                            <li class="block mb-1"><router-link :to="{name: 'transactions'}" class="block px-3 py-2 rounded-md hover:bg-gray-300 text-gray-600" :class="{'bg-gray-300': $route.name == 'transactions'}">All transactions</router-link></li>
                            <li class="block mb-1"><router-link :to="{name: 'success-transactions'}" class="block px-3 py-2 rounded-md hover:bg-gray-300 text-gray-600" :class="{'bg-gray-300': $route.name == 'success-transactions'}">Success</router-link></li>
                            <li class="block mb-1"><router-link :to="{name: 'refunded-transactions'}" class="block px-3 py-2 rounded-md hover:bg-gray-300 text-gray-600" :class="{'bg-gray-300': $route.name == 'refunded-transactions'}">Refunded</router-link></li>
                            <li class="block mb-1"><router-link :to="{name: 'pending-transactions'}" class="block px-3 py-2 rounded-md hover:bg-gray-300 text-gray-600" :class="{'bg-gray-300': $route.name == 'pending-transactions'}">Pending</router-link></li>
                            <li class="block mb-1"><router-link :to="{name: 'rejected-transactions'}" class="block px-3 py-2 rounded-md hover:bg-gray-300 text-gray-600" :class="{'bg-gray-300': $route.name == 'rejected-transactions'}">Rejected</router-link></li>
                            <li class="block mb-1"><router-link :to="{name: 'checked-transactions'}" class="block px-3 py-2 rounded-md hover:bg-gray-300 text-gray-600" :class="{'bg-gray-300': $route.name == 'checked-transactions'}">AML checked</router-link></li>
                            
                        </ul>
                    </div>
                    <div class="" v-if="$route.name == 'users' || $route.name == 'blocked-users' || $route.name == 'approve-pending-users'">
                        <div class="text-lg mb-4">Users</div>
                        <ul class="list-none text-sm">
                            <li class="block mb-1"><router-link :to="{name: 'users'}" class="block px-3 py-2 rounded-md hover:bg-gray-300 text-gray-600" :class="{'bg-gray-300': $route.name == 'users'}">All Users</router-link></li>
                            <li class="block mb-1"><router-link :to="{name: 'blocked-users'}" class="block px-3 py-2 rounded-md hover:bg-gray-300 text-gray-600" :class="{'bg-gray-300': $route.name == 'blocked-users'}">Blocked Users</router-link></li>
                            <li class="block mb-1"><router-link :to="{name: 'approve-pending-users'}" class="block px-3 py-2 rounded-md hover:bg-gray-300 text-gray-600" :class="{'bg-gray-300': $route.name == 'approve-pending-users'}">Pending Users</router-link></li>
                        </ul>
                    </div>
                    <div class="" v-if="$route.name == 'finance' || $route.name == 'forex' || $route.name == 'tarrifs' || $route.name == 'accounts' || $route.name == 'aml-rules' || $route.name == 'countries'">
                        <div class="text-lg mb-4">Finance</div>
                        <ul class="list-none text-sm">
                            <li class="block mb-1"><router-link :to="{name: 'accounts'}" class="block px-3 py-2 rounded-md hover:bg-gray-300 text-gray-600" :class="{'bg-gray-300': $route.name == 'accounts'}">Accounts</router-link></li>
                            <li class="block mb-1 hidden"><router-link :to="{name: 'tarrifs'}" class="block px-3 py-2 rounded-md hover:bg-gray-300 text-gray-600" :class="{'bg-gray-300': $route.name == 'tarrifs'}">Tarrifs</router-link></li>
                            <li class="block mb-1"><router-link :to="{name: 'forex'}" class="block px-3 py-2 rounded-md hover:bg-gray-300 text-gray-600" :class="{'bg-gray-300': $route.name == 'forex'}">Forex Rates</router-link></li>
                            <li class="block mb-1"><router-link :to="{name: 'aml-rules'}" class="block px-3 py-2 rounded-md hover:bg-gray-300 text-gray-600" :class="{'bg-gray-300': $route.name == 'aml-rules'}">AML Rules</router-link></li>
                            <li class="block mb-1"><router-link :to="{name: 'countries'}" class="block px-3 py-2 rounded-md hover:bg-gray-300 text-gray-600" :class="{'bg-gray-300': $route.name == 'countries'}">Countries</router-link></li>
                        </ul>
                    </div>
                    <div class="" v-if="$route.name =='settings' || $route.name == 'view-system-user' || $route.name == 'view-system-affiliates'">
                        <div class="text-lg mb-4">Settings</div>
                        <ul class="list-none text-sm">
                            <li class="block mb-1"><router-link :to="{name: 'view-system-user'}" class="block px-3 py-2 rounded-md hover:bg-gray-300 text-gray-600" :class="{'bg-gray-300': $route.name == 'view-system-user'}">System Users</router-link></li>
                            <li class="block mb-1"><router-link :to="{name: 'view-system-affiliates'}" class="block px-3 py-2 rounded-md hover:bg-gray-300 text-gray-600" :class="{'bg-gray-300': $route.name == 'view-system-affiliates'}">User Afilliates</router-link></li>
                        </ul>
                    </div>
                    
                    <div class="" v-if="$route.name == 'tickets' || $route.name == 'tickets-unresolved' || $route.name == 'tickets-search'">
                        <div class="text-lg mb-4">Support</div>
                        <ul class="list-none text-sm">
                            <li class="block mb-1"><router-link :to="{name: 'tickets-search'}" class="block px-3 py-2 rounded-md hover:bg-gray-300 text-gray-600" :class="{'bg-gray-300': $route.name == 'tickets-search'}">Search Profile</router-link></li>
                            <li class="block mb-1"><router-link :to="{name: 'tickets'}" class="block px-3 py-2 rounded-md hover:bg-gray-300 text-gray-600" :class="{'bg-gray-300': $route.name == 'tickets'}">All issues</router-link></li>
                        </ul>
                    </div>
                </div>
                <div class="h-full top-0 bg-gray-50 overflow-auto col-span-12" :class="{'md:col-span-12': $route.name == 'dashboard', 'md:col-span-10': $route.name != 'dashboard'}">
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            isAdmin: false,
            isSupport: false,
            isFinance: false
        }
    },
    components: {
    },
    mounted(){
        let vm = this;
        if(!vm.$cookies.get("accessToken") || !vm.$cookies.get("roleID")){
            vm.$router.push({
            name: 'login'
         })
        } else {
            if(vm.$cookies.get("roleID") == 1){
                vm.isAdmin = true
            }
            if(vm.$cookies.get("roleID") == 2){
                vm.isSupport = true
            }
            if(vm.$cookies.get("roleID") == 3){
                vm.isFinance = true
            }

        }
       
    },
    methods: {
        
        logout() {
            let vm = this;
            vm.$cookies.remove("accessToken");
            vm.$cookies.remove("UserRole");
            vm.$cookies.remove("email");
            vm.$router.push({
                name: 'login'
            });
        },
    }
}
</script>