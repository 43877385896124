<template>
<div class="relative p-4">
    <div class="flex w-full pb-4 items-center">
        <div class="flex-shrink">
            <div class="text-lg">Success Transactions</div>
            <div class="text-xs text-gray-500">{{startDate | formatDate}} - {{endDate | formatDate}}</div>
        </div>
        <div class="flex-grow text-right">
            <div class="inline-block mr-4">
                <input class="px-3 py-2 rounded-md border text-xs focus:outline-none text-gray-700 bg-white focus:border-gray-400" placeholder="Search" v-model="search" v-on:keyup.enter="searchRecord">
            </div>
            <input type="date" class="text-xs border px-3 py-2 rounded-md ml-2" placeholder="Start Date" v-model="startDate" />
            <input type="date" class="text-xs border px-3 py-2 rounded-md ml-2" placeholder="End Date" v-model="endDate" />
            <button class="px-3 py-2 rounded-md border text-xs focus:outline-none text-white bg-blue-500 ml-2" @click="searchRecord()">Go</button>
            <div class="relative inline-block text-left hidden">
                <div>
                    <button type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-3 py-2 text-xs bg-white text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500" id="menu-button" aria-expanded="true" aria-haspopup="true">
                        Options
                        <!-- Heroicon name: solid/chevron-down -->
                        <svg class="-mr-1 ml-2 h-4 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                    </button>
                </div>

                <!--
                      Dropdown menu, show/hide based on menu state.

                      Entering: "transition ease-out duration-100"
                        From: "transform opacity-0 scale-95"
                        To: "transform opacity-100 scale-100"
                      Leaving: "transition ease-in duration-75"
                        From: "transform opacity-100 scale-100"
                        To: "transform opacity-0 scale-95"
                    -->
                <div class="hidden origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none text-xs" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                    <div class="py-1" role="none">
                        <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
                        <a href="#" class="text-gray-700 block px-4 py-2" role="menuitem" tabindex="-1" id="menu-item-0">Account settings</a>
                        <a href="#" class="text-gray-700 block px-4 py-2" role="menuitem" tabindex="-1" id="menu-item-1">Support</a>
                        <a href="#" class="text-gray-700 block px-4 py-2" role="menuitem" tabindex="-1" id="menu-item-2">License</a>
                        <form method="POST" action="#" role="none">
                            <button type="submit" class="text-gray-700 block w-full text-left px-4 py-2" role="menuitem" tabindex="-1" id="menu-item-3">
                                Sign out
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="block">
    <vuetable ref="vuetable" :css="cssTable" :http-options="httpOptions" :fields="columns" :sort-order="sortOrder" track-by="id" :append-params="moreParams" :per-page="limit" @vuetable:pagination-data="onPaginationData" @vuetable:loading="onLoading" @vuetable:loaded="onLoaded">
                 <div slot="payoutsstatus" slot-scope="props">
                        <div :class="props.rowData.payouts.status | transClassStatus"><strong>{{props.rowData.payouts.status | transactionsStatus}}</strong></div>
                    </div>
                    <div slot="payoutsinflowStatus" slot-scope="props">
                        <div :class="props.rowData.payouts.inflowStatus | transClassStatus"><strong>{{props.rowData.payouts.inflowStatus  | transactionsStatus}}</strong></div>
                    </div>
                     <div slot="payoutsoutflowStatus" slot-scope="props">
                        <div :class="props.rowData.payouts.outflowStatus | transClassStatus"><strong>{{props.rowData.payouts.outflowStatus | transactionsStatus}}</strong></div>
                    </div>
                    
                     <div slot="Sender" slot-scope="props">
                      <div class="">{{props.rowData.customers.names}}</div>
                      <div class="text-gray-500">{{props.rowData.customers.phone}}</div>
                        
                    </div>
                    <div slot="full_names" slot-scope="props">
                        <div class="">{{props.rowData.benefitiaries.full_names}}</div>
                        <div class="text-gray-500" v-show="props.rowData.benefitiaries.payout_type =='Bank Payments'">{{props.rowData.benefitiaries.bank_name}}</div>
                        <div class="text-gray-500" v-show="props.rowData.benefitiaries.payout_type =='Bank Payments'">{{props.rowData.benefitiaries.bank_account}}</div>
                        <div class="text-gray-500" v-show="props.rowData.benefitiaries.payout_type =='Mobile Payments'">{{props.rowData.benefitiaries.phone}}</div>
                    </div>
                    <div slot="senderAmount" slot-scope="props">
                      <div class="text-sm font-medium">{{props.rowData.customers.country}} {{props.rowData.payouts.sendAmount | formatAmount}}</div>
                        <div class="text-xs text-gray-600">Charged: USD {{props.rowData.payouts.payAmount | formatAmount}}</div>
                        
                    </div>
                    <div slot="receiveAmount" slot-scope="props">
                    
                        <div class="text-xs text-gray-600"> KES {{props.rowData.payouts.payoutAmount | formatAmount}}</div>
                        
                    </div>
                    
                    

            </vuetable>
            <div class="block">
                <vuetable-pagination-info :css="paginationInfo" ref="paginationInfo"></vuetable-pagination-info>
                <vuetable-pagination ref="pagination" :css="pagination" @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
            </div>
        
    </div>
</div>
</template>
<style scoped>
@import "https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css";
@import "https://cdn.datatables.net/1.10.20/css/jquery.dataTables.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css";
</style>
<script>
import moment from "moment";
import loader from "@/components/loader";
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import numeral from "numeral";
//you need to import the CSS manually

export default {
    data: function () {
        return {
            httpOptions: {
                baseURL: 'https://switch.tuma.com/api/v1/transactions',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + this.$cookies.get("accessToken")
                }
            },
            cssTable: {
                tableWrapper: "",
                tableHeaderClass: "mb-0",
                tableBodyClass: "mb-0",
                loadingClass: 'loading',
                ascendingIcon: "fa fa-chevron-up",
                descendingIcon: "fa fa-chevron-down",
                ascendingIcon: 'green chevron up icon',
                descendingIcon: 'green chevron down icon',
                sortableIcon: "",
                detailRowClass: "vuetable-detail-row",
                handleIcon: "fa-bars text-secondary",
                renderIcon(classes, options) {
                    return `<i class="${classes.join(" ")}" ${options}></span>`;
                }
            },
             pagination: {
                wrapperClass: 'ui right floated pagination menu',
                activeClass: 'active',
                disabledClass: 'disabled',
                pageClass: 'item',
                linkClass: 'icon item',
                paginationClass: 'ui bottom attached segment grid',
                paginationInfoClass: 'left floated left aligned six wide column',
                dropdownClass: 'ui search dropdown',
                icons: {
                    first: 'angle double left icon',
                    prev: 'left chevron icon',
                    next: 'right chevron icon',
                    last: 'angle double right icon',
                }
            },
            paginationInfo: {
                infoClass: 'left floated left aligned six wide column',
            },
            columns: [{
                    name: "payouts.id",
                    title: 'ID',
                    sortField: 'payouts.id',
                },
                {
                    name: "payoutsstatus",
                    title: 'Overal Status',
                    sortField: 'status',
                },
                 {
                    name: "payoutsinflowStatus",
                    title: 'Inflow Status',
                    sortField: 'inflowStatus',
                },
                 {
                    name: "payoutsoutflowStatus",
                    title: 'Outflow Status',
                    sortField: 'outflowStatus',
                },
                {
                    name: "payouts.transactionReference",
                    title: 'Ref',
                    sortField: 'payouts.transactionReference',
                },
                {
                    name: "Sender",
                    title: 'Sender',
                },
                {
                    name:'full_names',
                    title:'Full Names'
                },
                {
                    name:'benefitiaries.payout_type',
                    title:'Payout Types'
                },
                {
                    name:'senderAmount',
                    title:'Sender Amount'
                },
                {
                    name:'receiveAmount',
                    title:'Receive Amount'
                },
                {
                    name: 'created_at',
                    title: 'Date',
                    sortField: 'created_at',
                    titleClass: '',
                    dataClass: ''

                },
                

            ],
             sortOrder: [{
                field: 'created_at',
                direction: 'desc'
            }],
            is_partial_resulting: false,
            moreParams: {
                start: '',
                filter: '',
                end: '',
                status:1
            },
            transactions: [],
            search: "",
            sort: "desc",
            page: 1,
            total: 0,
            limit: 10,
            errorStatus: false,
            errorMessage: "",
            dateFormat: "dd-mm-yyyy",
            isLoading: false,
            startDate: "",
            endDate: "",
            totalPages: 0
        }
    },
    components: {
        loader,Vuetable,VuetablePagination,VuetablePaginationInfo
    },
    mounted() {
        this.endDate = moment().format("YYYY-MM-DD")
        this.startDate = moment().subtract(7, 'd').format('YYYY-MM-DD');
      

    },
    methods: {
        createdAt: function (value) {
            if (!value) {
                return '-';
            } else {
                return moment(value).format('h:mm a,DD MMM YYYY');
            }

        },

        searchRecord: function () {
            this.moreParams.filter = this.search_match;
            this.moreParams.start = this.startDate;
            this.moreParams.end = this.endDate;
            this.$refs.vuetable.refresh();
        },

        dataManager(sortOrder, pagination) {
            if (this.data.length < 1) return;

            let local = this.data;

            // sortOrder can be empty, so we have to check for that as well
            if (sortOrder.length > 0) {
                console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
                local = _.orderBy(
                    local,
                    sortOrder[0].sortField,
                    sortOrder[0].direction
                );
            }

            pagination = this.$refs.vuetable.makePagination(
                local.length,
                this.perPage
            );
            console.log('pagination:', pagination)
            let from = pagination.from - 1;
            let to = from + this.perPage;

            return {
                pagination: pagination,
                data: _.slice(local, from, to)
            };
        },

        onPaginationData(paginationData) {
            this.$refs.paginationInfo.setPaginationData(paginationData)
            this.$refs.pagination.setPaginationData(paginationData)
        },

        onChangePage(page) {
            this.$refs.vuetable.changePage(page)
        },

        onLoading() {
            this.loading = true
            console.log('loading... show your spinner here')
        },
        onLoaded() {
            this.loading = false
            console.log('loaded! .. hide your spinner here');

        },
    },
    filters: {
         transactionsStatus(value) {
            if (value == 1) {
                return "Delivered"
            } else if (value == 2) {
                return "On Hold"
            } else if (value == 3) {
                return "Rejected"
            }
            else if (value == -2) {
                return "Failed"
            }
            else if (value == -7) {
                return "Refunded"
            } else {
                return "Pending"
            }
        },
        transactionsInOutStatus(value) {
            if (value == 1) {
                return "Success"
            }
            else if (value == -7) {
                return "warning"
            }
             else {
                return "Failed"
            }
        },
        transClassStatus(value) {
            if (value == '1') {
                return "text-green-500"
            } else if (value == '2' || value == '-7') {
                return "text-yellow-500"
            } else if (value == '3' || value == '-2') {
                return "text-red-500"
            } else {
                return "text-gray-500"
            }
        },
        formatDate(value) {
            if (!value) return "";
            return moment(value).format("DD MMM YYYY");
        },
         formatAmount(value){
            return numeral(value).format("0,0.00");
        },
    }
}
</script>
