<template>
<div class="fixed left-0 top-0 bottom-0 w-full z-1 bg-black bg-opacity-50 transition" id="ticket-details">
    <div class="absolute top-0 bottom-0 right-0 w-1/2 lg:w-1/3 bg-white z-2 overflow-auto">
        <div class="flex px-4 py-2 border-b sticky top-0 bg-white items-center">
            <div class="flex-grow block">
                <div class="text-xl font-medium">Raise issue</div>
                <div class="text-xs text-gray-500">Create support ticket and assign it to a support assistance</div>
            </div>
            <div class="flex-shrink cursor-pointer" @click="hideModal">
                <svg viewBox="0 0 18 18" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                    <path fill="none" d="M0 0h18v18H0Zm0 0h18v18H0Z" />
                    <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9Z" /></svg>
            </div>
        </div>
        <form @submit.prevent="createTicket">
            <div class="block m-4 rounded-md">
                <div class="flex px-2 gap-2">
                    <div class="inline-block border-b-4 text-gray-700 text-xs px-4 py-2 rounded-t-md">Customer</div>
                    <div class="inline-block text-gray-700 text-xs px-4 py-2 rounded-t-md" v-show="showTransactionsDetails">Transaction</div>
                </div>
                <div class="p-4 bg-gray-100 p-4 rounded-b-md rounded-t-md">
                    <div class="text-lg">{{clientInfo.names}}</div>
                    <div class="text-sm flex gap-6 text-gray-500 pb-4 mb-4 border-b border-gray-300">
                        <div class="text-sm">{{clientInfo.phone}}</div>
                        <div class="text-sm">{{clientInfo.email}}</div>
                        <div class="text-sm">{{clientInfo.country}}</div>
                    </div>
                    <div class="text-xs text-gray-500">
                        <span class="font-medium">Last Used:</span> {{clientInfo.updated_at | formatDate}}
                    </div>
                </div>

            </div>
            <!-- Notes -->
            <div class="relative p-4 mb-12">

                <div class="grid grid-cols-2 gap-4">
                    <div class="block">
                        <label for="" class="text-sm mb-1 block">Title</label>
                        <input class="text-xs border border-gray-300 px-3 py-2 w-full block rounded-md focus:outline-none" type="text" v-model="title" required />
                    </div>
                    <div class="block">
                        <label for="" class="text-sm mb-1 block">Issue type</label>
                        <select class="text-xs border border-gray-300 px-3 py-2 w-full block rounded-md focus:outline-none" v-model="type" required>
                            <option value="Transaction not reflecting">Transaction not reflecting</option>
                            <option value="Money not delivered">Money not delivered</option>
                            <option value="Transaction pending">Transaction pending</option>
                            <option value="Deposit issue">Deposit issue</option>
                            <option value="Account issue">Account issue</option>
                        </select>
                    </div>
                    
                    
                </div>

                <div class="grid grid-cols-2 gap-4">
                    <div class="block">
                        <label for="" class="text-sm mb-1 block">Priority</label>
                        <select class="text-xs border border-gray-300 px-3 py-2 w-full block rounded-md focus:outline-none" v-model="importance" required>
                            <option>High</option>
                            <option>Low</option>
                            <option>Medium</option>
                        </select>
                    </div>
                    <div class="block">
                        <label for="" class="text-sm mb-1 block">Assign issue</label>
                        <select class="text-xs border border-gray-300 px-3 py-2 w-full block rounded-md focus:outline-none" v-model="assignID" required>
            
                            <option v-for="s_id in users" :key="s_id.id" :value="s_id.id">{{s_id.name}}</option>
                        </select>
                    </div>
                    
                </div>
                <div class="block my-4 py-2">
                    <div class="text-sm text-gray-500 mb-1">Add Note</div>
                    <textarea class="rounded-md h-24 border outline-none align-top p-3 w-full bg-blue-50 mb-2" v-model="description" required></textarea>
                    <div class="text-xs text-gray-400 italic">Note will be saved when you create this issue</div>
                </div>

            </div>

            <div class="fixed bottom-0 right-0 block bg-white z-10 border-t px-4 py-2 w-1/2 lg:w-1/3">
                <button class="px-3 py-2 rounded-md border text-sm focus:outline-none text-gray-700 bg-gray-200" @click="hideModal">Cancel</button>
                <button class="px-3 py-2 rounded-md border text-sm focus:outline-none text-white bg-yellow-500 float-right ml-4" type="submit">Create Issue</button>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import $ from "jquery";
import store from "../store";
import moment from "moment";

export default {
    data: function () {
        return {
            users: [],
            type:"",
            title:"",
            description:"",
            importance:"",
            assignID:"",
            showTransactionsDetails: false
        }
    },
    computed: {
        clientInfo() {
            if (store.state.userData) {
                this.fetchUsersData()
                return store.state.userData
            }
        },
        transactionsInfo(){
            if(store.state.transactionData) {
                this.showTransactionsDetails = false
                return store.state.transactionData
            }
        }
    },
    methods: {
        hideModal() {
            this.$parent.modalTicketCreate = false;
        },
        fetchUsersData() {
            let vm = this

            $.get({
                url: store.state.rootUrl + 'v1/users',
                type: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + vm.$cookies.get("accessToken")
                },
                data: {
                    limit: 30,

                },
                success: function (response, status, jQxhr) {
                    vm.isLoading = false
                    vm.users = response.data;
                    
                },
                error: function (jQxhr, status, error) {
                    var errorMessage = jQxhr.responseJSON.errors[0];
                    var statustext = jQxhr.responseJSON.message;
                }
            });

        },
        createTicket(){
            let vm  = this;
            vm.$swal({
                title: 'Are you sure?',
                text: 'You want to create ticket',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, create it!',
                cancelButtonText: 'Cancel',
                showCloseButton: true,
                showLoaderOnConfirm: true
                }).then((result) => {
                if(result.value) {
                    $.post({
                        url: store.state.rootUrl + "v1/issue/create",
                        type: "POST",
                         headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + vm.$cookies.get("accessToken")
                    },
                        data: JSON.stringify({
                            type:vm.type,
                            title:vm.title,
                            importance: vm.importance,
                            description: vm.description,
                            assigned: vm.assignID,
                            raisedBy: store.state.userData.id,
                            assignedBy:vm.$cookies.get("userID"),
                            status: 0
                        }),
                        success: function (response, status, jQxhr) {
                            
                            vm.$swal('Sent', 'Successfully created ticket', 'success')
                            vm.$parent.getAllTickets(1)
                            vm.hideModal()
                            
                        },
                        error: function (jQxhr, status, error) {
                             vm.$swal('Failed', 'Failed created ticket', 'error')
                             vm.hideModal()
                        }
                    });
                    
                } else {
                    vm.$swal('Cancelled', 'Cancel to created event ticket Type', 'info')
                    vm.$modal.hide('example')
                }
            })

        },
    },
    filters: {
        formatDate: function (value) {
            if (!value) return "";
            return moment(value).fromNow();
        },
    }

}
</script>
