<template>
<div class="fixed left-0 top-0 bottom-0 w-full z-1 bg-black bg-opacity-10 transition" id="aml-rules-add">
    <div class="absolute top-0 bottom-0 right-0 w-1/3 bg-white z-2 p-4 overflow-auto">
        <div class="block mb-4">
            <div class="text-lg">Approve Account</div>
            <div v-show="errorSatus" class="text-red-500 px-2 py-1 rounded-sm bg-red-100 text-xs">
                {{errorMessage}}
            </div>
        </div>
        <form @submit.prevent="updateAccount">
            <div class="block my-6">
                <label for="" class="text-sm mb-1 block">Name</label>
                <input class="text-xs border border-gray-300 px-3 py-2 w-full rounded-md focus:outline-none" v-model="name" disabled />
            </div>
            <div class="block my-6">
                <label for="" class="text-sm mb-1 block">Phone</label>
                <input class="text-xs border border-gray-300 px-3 py-2 w-full rounded-md focus:outline-none" v-model="msisdn" disabled />
            </div>
            <div class="block my-6 py-2">
                <div class="text-sm text-gray-500 mb-1">Reason</div>
                <textarea class="rounded-md h-24 border outline-none align-top p-3 w-full bg-blue-50 mb-2" v-model="reason" required></textarea>
            </div>

            <div class="block mt-16">
                <button class="px-3 py-2 rounded-md border text-sm focus:outline-none text-gray-700 bg-gray-200 mr-4" type="button" @click="hideModal">Cancel</button>
                <button class="px-3 py-2 rounded-md border text-sm focus:outline-none text-white bg-green-500" type="submit" id="updateAccount">Approve Account</button>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import $ from "jquery";
import store from "../store";
export default {
    data: function () {
        return {
            name: '',
            msisdn: '',
            reason: '',
            errorMessage: "",
            errorSatus: false,
        }
    },
    computed: {
        account() {
            return store.state.userData
        }
    },
    watch: {
        account(newCount, oldCount) {
            console.log(`We have ` + JSON.stringify(newCount))
            this.name = newCount.names
            this.msisdn = newCount.phone
            
        }
    },

    methods: {
        hideModal() {
            this.$parent.approveModal = false;
        },
        updateAccount() {
            let vm = this
            $('#updateAccount').html('<i class="fa fa-spinner fa-spin"></i> Please wait ...');
            
            $.post({
                url: store.state.rootUrl + 'v1/customer/approve',
                type: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + vm.$cookies.get("accessToken")
                },
                data: JSON.stringify({
                    customerId: vm.account.id,
                    approvedBy: vm.$cookies.get("userID"),
                    approveReason: vm.reason

                }),
                success: function (response) {

                    $('#updateAccount').text('Approve Account')
                    if (response.responseCode != 200) {
                        vm.errorMessage = response.message;
                        vm.$swal('Edit Account', vm.errorMessage , 'error')
                        vm.errorSatus = true;
                    } else {
                        vm.$swal('Edit Account', response.message , 'success')
                        vm.hideModal()
                        vm.$parent.$refs.vuetable.refresh();
                    }
                },
                error: function (jQxhr, status, error) {
                    var errorMessage = jQxhr.responseJSON.errors[0];
                    var statustext = jQxhr.responseJSON.message;
                    vm.errorSatus = true;
                    $('#createRule').text('Approve Account')
                    if (!statustext) {
                        vm.errorMessage = "An error occurred. Try again later.";
                    } else {
                        vm.errorMessage = statustext + " " + errorMessage;
                    }
                }
            });
        },
    },

}
</script>
