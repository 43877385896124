<template>
<div class="relative p-4">
    <div class="flex w-full pb-4 items-center">
        <div class="flex-shrink">
            <div class="text-lg">All issues</div>
            <div class="text-xs text-gray-500">{{startDate | formatDate}} - {{endDate | formatDate}}</div>
        </div>
        <div class="flex-grow text-right">
            <input type="date" class="text-xs border px-3 py-2 rounded-md ml-2" placeholder="Start Date" v-model="startDate"/>
            <input type="date" class="text-xs border px-3 py-2 rounded-md ml-2" placeholder="End Date" v-model="endDate"/>
            <button class="px-3 py-2 rounded-md border text-xs focus:outline-none text-white bg-blue-500 ml-2">Go</button>
        </div>
    </div>
    <div class="block">
    <vuetable ref="vuetableTickets" :css="cssTable" :http-options="httpOptionsTickets" :fields="columnsTickets" :sort-order="sortOrderTickets" track-by="id" :append-params="moreParamsTickets" :per-page="limit" @vuetable:pagination-data="onPaginationData" @vuetable:loading="onLoading" @vuetable:loaded="onLoaded">
                <div slot="status" slot-scope="props">
                    <div :class="props.rowData.status | ticketClassStatus"><strong>{{props.rowData.status | formatTicketStatus}}</strong></div>
                </div>
                <div slot="assigned" slot-scope="props">
                    <div><strong>{{props.rowData.assigned_user.name}}</strong></div>
                </div>
                <div slot="importance" slot-scope="props">
                    <div class="">{{props.rowData.importance}}</div>

                </div>
              
                <div slot="created_at" slot-scope="props">

                    <div class="text-xs text-gray-600"> {{props.rowData.created_at | formatDate}}</div>

                </div>
                <div class="table-button-container" slot="action" slot-scope="props">
                    <button class="text-green-500" @click="showTicketDetails(props.rowData)"><i class="fa fa-check" aria-hidden="true"></i> View Ticket </button>
                </div>

            </vuetable>
            <div class="block">
                <vuetable-pagination-info :css="paginationInfo" ref="paginationInfo"></vuetable-pagination-info>
                <vuetable-pagination ref="pagination" :css="pagination" @vuetable-pagination:change-page="onChangePageTicket"></vuetable-pagination>
            </div>
        
    </div>
    <TicketDetails v-show="this.modalTickets" />
</div>
</template>
<style scoped>
@import "https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css";
@import "https://cdn.datatables.net/1.10.20/css/jquery.dataTables.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css";
</style>
<script>
import TicketDetails from "./modal-ticket";
import $ from 'jquery'
import store from "../store";
import moment from "moment";
import loader from "@/components/loader";
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
export default {
    data() {
        return {httpOptionsTickets: {
                baseURL: 'https://api.helasend.com/api/v1/issues/all',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + this.$cookies.get("accessToken")
                }
            },
            cssTable: {
                tableWrapper: "",
                tableHeaderClass: "mb-0",
                tableBodyClass: "mb-0",
                loadingClass: 'loading',
                ascendingIcon: "fa fa-chevron-up",
                descendingIcon: "fa fa-chevron-down",
                ascendingIcon: 'green chevron up icon',
                descendingIcon: 'green chevron down icon',
                sortableIcon: "",
                detailRowClass: "vuetable-detail-row",
                handleIcon: "fa-bars text-secondary",
                renderIcon(classes, options) {
                    return `<i class="${classes.join(" ")}" ${options}></span>`;
                }
            },
            pagination: {
                wrapperClass: 'ui right floated pagination menu',
                activeClass: 'active',
                disabledClass: 'disabled',
                pageClass: 'item',
                linkClass: 'icon item',
                paginationClass: 'ui bottom attached segment grid',
                paginationInfoClass: 'left floated left aligned six wide column',
                dropdownClass: 'ui search dropdown',
                icons: {
                    first: 'angle double left icon',
                    prev: 'left chevron icon',
                    next: 'right chevron icon',
                    last: 'angle double right icon',
                }
            },
            paginationInfo: {
                infoClass: 'left floated left aligned six wide column',
            },
             columnsTickets: [
                {
                    name: "status",
                    title: 'Status',
                    sortField: 'status',
                },
                {
                    name: "type",
                    title: 'Type',
                    sortField: 'type',
                },
                {
                    name: "importance",
                    title: 'Importance',
                    sortField: 'importance',
                },
                
                {
                    name: 'created_at',
                    title: 'Date',
                    sortField: 'created_at',
                    titleClass: '',
                    dataClass: ''

                },
                'action'

            ],
            sortOrderTickets: [{
                field: 'created_at',
                direction: 'desc'
            }],
            is_partial_resulting: false,
            moreParamsTickets: {
                start: '',
                filter: '',
                end: '',
            },
            modalTickets: false,
            tickets: [],
            search: "",
            sort: "desc",
            page: 1,
            total: 0,
            limit: 10,
            errorStatus: false,
            errorMessage: "",
            dateFormat: "dd-mm-yyyy",
            isLoading: false,
            startDate: "",
            endDate: "",
            totalPages: 0
        }
    },
    mounted() {
        this.endDate = moment().format("YYYY-MM-DD")
        this.startDate = moment().subtract(7, 'd').format('YYYY-MM-DD');
       

    },
    components: {
        TicketDetails,loader,Vuetable,VuetablePagination,VuetablePaginationInfo
    },
    methods: {
        showTicketDetails(ticket) {
           // console.log(JSON.stringify(ticket))
            store.commit("setTicketInformation", ticket);
            this.modalTickets = true;

        },
         onPaginationData(paginationData) {
            this.$refs.paginationInfo.setPaginationData(paginationData)
            this.$refs.pagination.setPaginationData(paginationData)
        },
         onChangePageTicket(page) {
            this.$refs.vuetableTickets.changePage(page)
        },
        onLoading() {
            this.loading = true
            console.log('loading... show your spinner here')
        },
        onLoaded() {
            this.loading = false
            console.log('loaded! .. hide your spinner here');

        },
    },
    filters: {
        formatTicketStatus(value){
            
            if(value == 0 || value =='') {
                return "Pending"
            }
            else if(value == '1') {
                return "Assigned"
            }
            else if (value == '2') {
                return "Resolved"
            }
            else if (value == '3') {
                return "Closed"
            }
            else {
                return "Unknown"
            }
        },
         ticketClassStatus(value) {
            if (value == 0 || value =='') {
                return "text-yellow-500"
            } else if (value == '1') {
                return "text-blue-500"
            } else if (value == '2') {
                return "text-green-500"
            } 
            else if (value == '3') {
                return "text-red-500"
            }
            else {
                return "text-gray-500"
            }
        },
        formatDate(value) {
            if (!value) return "";
            return moment(value).format("DD MMM YYYY");
        },
        ticketImportanceClass: function (value) {
            if(value == 'High'){
                return "text-red-500"
            }
            else if(value == 'Low'){
                return "text-blue-500"
            }
            else {
                return "text-yellow-500"
            }
        }
    }
}
</script>
