<template>
<div class="relative py-4">
    <div class="md:flex block md:pb-4 items-center px-4">
        <div class="block md:flex-shrink">
            <div class="md:text-lg text-2xl font-medium text-center md:text-left">Dashboard</div>
            <div class="hidden md:block text-xs text-gray-500">{{startDate | formatDate}} - {{endDate | formatDate}}</div>
        </div>
<!--            <div class="hidden md:flex-grow grid grid-cols-2 md:block gap-4 md:gap-0 md:text-right my-2 md:my-0">
            <input type="date" class="col md:inline-block text-xs border px-3 py-2 rounded-md md:ml-2" placeholder="Start Date" v-model="startDate" @change="getReportSearch()"/>
            <input type="date" class="col md:inline-block text-xs border px-3 py-2 rounded-md md:ml-2" placeholder="End Date" v-model="endDate" @change="getReportSearch()" />
        </div>-->
        <div class="md:flex-grow block gap-2 md:gap-0 md:text-right text-center my-2 md:my-0 mb-4">
            <div class="inline-block bg-white border rounded block divide-x mr-2 text-gray-600 text-sm">
                <button class="px-3 py-1.5"  :class="{'bg-gray-200':summaryDays == 0}" @click="getResponseDay(0)">Today</button>
                <button class="px-3 py-1.5"  :class="{'bg-gray-200':summaryDays == 1}" @click="getResponseDay(1)">Yesterday</button>
            </div>
            <v-date-picker v-model="dateRangeFirst" is-range mode="date" :masks="masks" class="bg-white border px-3 py-2 rounded text-gray-600 text-sm">
                <template v-slot="{ inputValue, inputEvents }">
                    <svg width="16" height="16" class="mr-2 inline-block" fill="#777" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g>
                    <path d="M7 11h2v2H7Zm0 4h2v2H7Zm4-4h2v2h-2Zm0 4h2v2h-2Zm4-4h2v2h-2Zm0 4h2v2h-2Z"/><path d="M5 22h14c1.1 0 2-.9 2-2V8 6c0-1.11-.9-2-2-2h-2V2h-2v2H9V2H7v2H5c-1.11 0-2 .89-2 2v2 12c0 1.1.89 2 2 2ZM19 8l0 12H4.99V8h14Z"/></g></svg>
                    <span v-on="inputEvents.start">{{inputValue.start}}</span> - <span v-on="inputEvents.end">{{inputValue.end}}</span>
                </template>
            </v-date-picker>
        </div>
    </div>

    <div class="relative mb-6 px-4">
        <div class="grid md:grid-cols-4 grid-cols-2 gap-4">
            <div class="col shadow-md bg-white md:p-6 p-4 rounded-md border-t-4 border-purple-400 overflow-hidden">
                <div class="text-md mb-1">Total PayIn</div>
                <div class="text-2xl font-medium">{{inflows}}</div>
                <div class="mt-4">
                    <div class="text-sm text-red-500 font-medium" id="percentFlow"><span id="signFlow"></span> {{percentInflows}}%</div>
                </div>
            </div>
            <div class="col shadow-md bg-white md:p-6 p-4 rounded-md border-t-4 border-blue-400 overflow-hidden">
                <div class="text-md mb-1">Transactions</div>
                <div class="text-2xl font-medium">{{transactions}}</div>
                <div class="mt-4">
                    <div class="text-sm text-red-500 font-medium" id="percentTrans"><span id="signTrans"></span> {{percentTransactions}}%</div>
                </div>
            </div>
            <div class="col shadow-md bg-white md:p-6 p-4 rounded-md border-t-4 border-gray-400 overflow-hidden">
                <div class="text-md mb-1">Signups</div>
                <div class="text-2xl font-medium">{{signUps}}</div>
                <div class="mt-4">
                    <div class="text-sm text-red-500 font-medium" id="percentSignUps"><span id="signSignUps"></span> {{percentSignUps}}%</div>
                </div>
            </div>
            <div class="col shadow-md bg-white md:p-6 p-4 rounded-md border-t-4 border-green-400 overflow-hidden">
                <div class="text-md mb-1">P&L</div>
                <div class="text-2xl font-medium">{{pAndL}}</div>
                <div class="mt-4">
                    <div class="text-sm text-red-500 font-medium" id="percentPAndL"><span id="signPAndL"></span> {{percentpAndL}}%</div>
                </div>
            </div>
        </div>
    </div>

    <div class="flex mb-2 px-4 text-center md:text-right">
        <div class="flex-grow md:block gap-4 md:gap-0 md:text-right my-2 md:my-0">
            <div class="inline-block bg-white border rounded block divide-x mr-2 text-gray-600 text-sm">
                <button class="px-3 py-1.5" :class="{'bg-gray-200':days ==7}" @click="searchTransactionsSignUps(7)">7 days</button>
                <button class="px-3 py-1.5" :class="{'bg-gray-200':days ==30}" @click="searchTransactionsSignUps(30)">30 days</button>
            </div>
            <v-date-picker v-model="dateRange" mode="date" is-range :masks="masks" class="bg-white border px-3 py-2 rounded text-gray-600 text-sm">
                <template v-slot="{ inputValue, inputEvents }">
                    <svg width="16" height="16" class="mr-2 inline-block" fill="#777" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g><path d="M7 11h2v2H7Zm0 4h2v2H7Zm4-4h2v2h-2Zm0 4h2v2h-2Zm4-4h2v2h-2Zm0 4h2v2h-2Z"/><path d="M5 22h14c1.1 0 2-.9 2-2V8 6c0-1.11-.9-2-2-2h-2V2h-2v2H9V2H7v2H5c-1.11 0-2 .89-2 2v2 12c0 1.1.89 2 2 2ZM19 8l0 12H4.99V8h14Z"/></g></svg>
                    <span v-on="inputEvents.start">{{inputValue.start}}</span> - <span v-on="inputEvents.end">{{inputValue.end}}</span>
                </template>
            </v-date-picker>
        </div>
    </div>

    <div class="block mb-6">
        <div class="grid md:grid-cols-6 grid-cols-1 gap-6">
            <div class="bg-white md:rounded-md border shadow-md py-6 px-4 md:col-span-4 col">
                <div class="flex">
                    <div class="flex-grow block">
                        <div class="text-lg font-medium">Transactions</div>
                        <div class="text-sm mb-3 font-medium opacity-60">Last {{days}} days</div>
                    </div>
                </div>
                <div class="chart-container">
                    <line-chart :width="100" :height="50" :chart-data="datacollection" :options="options"></line-chart>
                </div>
            </div>
            <div class="relative bg-gray-800 md:rounded-md border shadow-md col md:col-span-2 p-4 text-white">
                <div class="flex">
                    <div class="flex-grow block">
                        <div class="text-lg font-medium">Signups</div>
                        <div class="text-sm mb-3 font-medium opacity-60">Last {{days}} days</div>
                    </div>
                </div>
                <div class="chart-container">
                    <bar-chart :chart-data="signCollection" :options="barOptions"></bar-chart>
                </div>
            </div>
        </div>
    </div>

    <div class="block mb-6">
        <div class="text-lg mb-3 font-medium px-4">Accounts</div>
        <div class="relative bg-white md:rounded-md border border-gray-200 overflow-hidden">
            <table class="w-full">
                <thead>
                    <tr class="bg-gray-50 text-sm text-left font-normal border-b">
                        <th class="py-2 px-4 border-r">Status</th>
                        <th class="py-2 px-4 border-r">Account Name</th>
                        <th class="py-2 px-4 border-r text-right">Available Amount</th>
                        <th class="py-2 px-4 border-r">Last updated</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="items-center border-b" v-for="(account, index) in accounts" :key="index+1">
                        <td class="py-2 px-4 border-r">
                            <div class="inline-block  text-xs px-4 py-1 rounded-full" :class="account.status | accountClassStatus">{{account.status | accountStatus}}</div>
                        </td>
                        <td class="py-3 px-4 border-r">
                            <div class="text-md font-medium">{{account.accountName}}</div>
                            <div class="text-xs">{{account.accountType}} {{account.accountNumber}}</div>
                        </td>
                        <td class="py-3 px-4 border-r text-right font-bold">
                            {{account.currency}} {{account.availableBalance | formatAmount}}
                        </td>
                        <td class="py-2 px-4 border-r relative">
                            <div class="text-sm">{{account.updated_at | formatDate}}</div>
                            <div class="text-xs">{{account.updated_at | formatTime}}</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
</template>

<script>
import $ from 'jquery'
import store from "../store";
import moment from "moment";
import loader from "@/components/loader";
import BarChart from './BarChart.js'
import LineChart from './LineChart.js'
import numeral from "numeral";

//you need to import the CSS manually

export default {
    data: function () {
        return {
            accounts: [],
            percentInflows: 0,
            percentSignUps: 0,
            percentTransactions: 0,
            percentpAndL: 0,
            inflows: "0",
            outflows: "0",
            transactions: "",
            fees: "0",
            mFSBalance: "0",
            IMBalance: "0",
            b2CBalance: "0",
            flexbalance: "0",
            pAndL: "",
            signUps: 0,
            startDate: "",
            endDate: "",
            startDate30: "",
            startDateTrans: "",
            summaryDays:0,
            days: 7,
            endDate30: "",
            isLoading: false,
            errorStatus: false,
            errorMessage: "",
            signCollection: {
                labels: [],
                datasets: [{
                    label: 'signups',
                    backgroundColor: '#DAF7A6',
                    data: []
                }, ]
            },
            datacollection: {
                labels: [],
                datasets: [{
                    label: 'Delivered',
                    backgroundColor: '#18a337',
                    data: []
                }, ]
            },
            options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                },
                responsive: true,
                maintainAspectRatio: true,
                elements: {
                    line: {
                        tension: 0
                    }
                }
            },
            barOptions: {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                },
                responsive: true,
                maintainAspectRatio: false,
                elements: {
                    line: {
                        tension: 0
                    }
                }
            },
            dateSingle: new Date(),
            dateRange: {
                start: new Date().setDate(new Date().getDate() - 7),
                end: new Date()
            },
            dateRangeFirst: {
                start: new Date(),
                end: new Date()
            },
            masks: {
                input: 'D MMM YYYY',
            },
        }

    },
    watch: {
        dateRangeFirst: function(val) {
            //do something when the data changes.
            let vm = this
            vm.startDate  = moment(val.start).format("YYYY-MM-DD")
            vm.endDate = moment(val.end).format("YYYY-MM-DD")
            const date1 = new Date(vm.startDate);
            const date2 = new Date(vm.endDate);
            const diffTime = Math.abs(date2 - date1);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
            vm.getResponseDay(diffDays)
        },
         dateRange: function(val) {
            //do something when the data changes.
            let vm = this
            vm.startDate30  = moment(val.start).format("YYYY-MM-DD")
            vm.endDate30 = moment(val.end).format("YYYY-MM-DD")
            const date1 = new Date(vm.startDate30);
            const date2 = new Date(vm.endDate30);
            const diffTime = Math.abs(date2 - date1);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
            vm.searchTransactionsSignUps(diffDays)
        }
    },
    components: {
        loader,
        LineChart,
        BarChart
    },
    mounted() {
        this.endDate = moment().format("YYYY-MM-DD")
        this.startDate = moment().subtract(0, 'd').format('YYYY-MM-DD');
        this.startDate30 = moment().subtract(this.days, 'd').format('YYYY-MM-DD');
        this.startDateTrans = moment().subtract(this.days, 'd').format('YYYY-MM-DD');
        this.dateRange.start = moment().subtract(this.days, 'd').format('D MMM YYYY');
        this.endDate30 = moment().format("YYYY-MM-DD")
        this.fetchAllDashboardStats()
        this.fetchGraphDashboardStats()
        this.fetchGraphDashboardUserStats()
        this.fetchAccount()

    },
    methods: {
        formatNumber(value) {
            if (!value) return 0
            return numeral(value).format("0,0");
        },
        searchTransactionsSignUps(days) {
            this.days = days
            this.startDate30 = moment().subtract(this.days, 'd').format('YYYY-MM-DD');
            this.endDate30 = moment().format("YYYY-MM-DD")
            this.fetchGraphDashboardStats()
            this.fetchGraphDashboardUserStats()
        },
        getResponseDay(days){
            let vm = this
            vm.summaryDays = days
            vm.startDate = moment().subtract(days, 'd').format('YYYY-MM-DD');
            
            if(days == 1){
                vm.endDate = moment().subtract(days, 'd').format('YYYY-MM-DD');
            }
            else {
                vm.endDate = moment().format("YYYY-MM-DD")
            }
            vm.datacollection = null
            vm.inflows = null
            vm.outflows = null
            vm.signUps = null
            vm.pandL = null
            vm.fees = null
            vm.mFSBalance = null
            vm.IMBalance = null
            vm.b2CBalance = null
            vm.flexbalance = null
            vm.fetchAllDashboardStats()
        },
        getReportSearch() {
            let vm = this
            vm.inflows = null
            vm.outflows = null
            vm.transactions = null
            vm.fees = null
            vm.mFSBalance = null
            vm.IMBalance = null
            vm.b2CBalance = null
            vm.flexbalance = null
            vm.fetchAllDashboardStats()
            vm.fetchGraphDashboardUserStats()

        },
        fetchGraphDashboardUserStats() {
            let vm = this
            vm.isLoading = true
            $.get({
                url: store.state.rootUrl + 'v1/transaction/graph',
                type: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + vm.$cookies.get("accessToken")
                },
                data: {
                    startDate: vm.startDate30,
                    endDate: vm.endDate30,

                },
                success: function (response, status, jQxhr) {
                    vm.isLoading = false
                    if (response.responseCode != 200) {
                        vm.errorStatus = true;
                        vm.errorMessage = response.message;
                    } else {
                        var list_user = response.data.customers;

                        let xUserData = []
                        let userCount = []

                        list_user.forEach(function (data) {

                            xUserData.unshift(moment(data['date']).format("D MMM"))

                            userCount.unshift(data['customers'])
                        });
                        vm.signCollection = {
                            labels: xUserData,
                            datasets: [{
                                label: 'Signups',
                                backgroundColor: '#2E8BC0',
                                data: userCount
                            }, ]
                        }

                    }
                },
                error: function (jQxhr, status, error) {
                    var errorMessage = jQxhr.responseJSON.errors[0];
                    var statustext = jQxhr.responseJSON.message;
                    vm.errorStatus = true;
                    vm.isLoading = false
                    $('#login').text('login')
                    if (!statustext) {
                        vm.errorMessage = "An error occurred. Try again later.";
                    } else {
                        vm.errorMessage = statustext + " " + errorMessage;
                    }
                }
            });
        },
        fetchGraphDashboardStats() {
            let vm = this
            vm.isLoading = true
            // vm.datacollection = {
            //     labels: [],
            //     datasets: [{
            //         label: 'Delivered',
            //         backgroundColor: '#DAF7A6',
            //         data: []
            //     }, ]
            // };
            $.get({
                url: store.state.rootUrl + 'v1/transaction/graph',
                type: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + vm.$cookies.get("accessToken")
                },
                data: {
                    startDate: vm.startDate30,
                    endDate: vm.endDate30,

                },
                success: function (response, status, jQxhr) {
                    vm.isLoading = false
                    if (response.responseCode != 200) {
                        vm.errorStatus = true;
                        vm.errorMessage = response.message;
                    } else {
                        var list_c = response.data.transactions;
                        let xData = [];
                        let deliveryData = []
                        list_c.forEach(function (data) {

                            xData.unshift(moment(data['date']).format("D MMM"))

                            deliveryData.unshift(data['delivered'])
                        });

                        vm.datacollection = {
                            labels: xData,
                            datasets: [{
                                label: 'Delivered',
                                backgroundColor: '#18a337',
                                data: deliveryData
                            }, ]
                        }
                    }
                },
                error: function (jQxhr, status, error) {
                    var errorMessage = jQxhr.responseJSON.errors[0];
                    var statustext = jQxhr.responseJSON.message;
                    vm.errorStatus = true;
                    vm.isLoading = false
                    $('#login').text('login')
                    if (!statustext) {
                        vm.errorMessage = "An error occurred. Try again later.";
                    } else {
                        vm.errorMessage = statustext + " " + errorMessage;
                    }
                }
            });
        },

        fetchAllDashboardStats() {
            let vm = this
            vm.isLoading = true

            $.get({
                url: store.state.rootUrl + 'v1/dashboard/analytics',
                type: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + vm.$cookies.get("accessToken")
                },
                data: {
                    startDate: vm.startDate,
                    endDate: vm.endDate,

                },
                success: function (response, status, jQxhr) {
                    vm.isLoading = false
                    if (response.responseCode != 200) {
                        vm.errorStatus = true;
                        vm.errorMessage = response.message;
                    } else {
                        vm.inflows = response.data.inflows
                        vm.outflows = response.data.outflows
                        vm.transactions = response.data.transactions
                        vm.fees = response.data.fees
                        vm.mFSBalance = response.data.mFSBalance
                        vm.IMBalance = response.data.IMBalance
                        vm.b2CBalance = response.data.b2CBalance
                        vm.flexbalance = response.data.flexbalance
                        vm.signUps = response.data.signups
                        vm.pAndL = response.data.pAndL

                        vm.getStatsAnaylsis(response.data.inflowsData,
                            response.data.transactions, response.data.signups, response.data.pAndLData)
                    }

                },
                error: function (jQxhr, status, error) {
                    var errorMessage = jQxhr.responseJSON.errors[0];
                    var statustext = jQxhr.responseJSON.message;
                    vm.errorStatus = true;
                    vm.isLoading = false
                    $('#login').text('login')
                    if (!statustext) {
                        vm.errorMessage = "An error occurred. Try again later.";
                    } else {
                        vm.errorMessage = statustext + " " + errorMessage;
                    }
                }
            });

        },
        getStatsAnaylsis(inflow, transactions, signups, pandL) {
            let vm = this
            const date1 = new Date(vm.startDate);
            const date2 = new Date(vm.endDate);
            const diffTime = Math.abs(date2 - date1);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            var startDateOld = moment().subtract((diffDays * 2), 'd').format('YYYY-MM-DD');
            var endDateOld = moment().subtract((diffDays + 1), 'd').format('YYYY-MM-DD');
            console.log("Difference Date " + startDateOld)
            $.get({
                url: store.state.rootUrl + 'v1/dashboard/analytics',
                type: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + vm.$cookies.get("accessToken")
                },
                data: {
                    startDate: startDateOld,
                    endDate: endDateOld,

                },
                success: function (response, status, jQxhr) {
                    vm.isLoading = false
                    if (response.responseCode != 200) {
                        vm.errorStatus = true;
                        vm.errorMessage = response.message;
                    } else {

                        if (Number(response.data.inflowsData) > Number(inflow)) {
                            document.getElementById('percentFlow').className = 'text-red-500';
                            $('#signFlow').text('-')
                            vm.percentInflows = (Number(response.data.inflowsData) / (Number(response.data.inflowsData) + Number(inflow)) * 100).toFixed(2)
                        } else {

                            if (inflow == 0) {
                                document.getElementById('percentFlow').className = 'text-red-500';
                                vm.percentInflows = 0

                            } else {
                                document.getElementById('percentFlow').className = 'text-green-500';
                                $('#signFlow').text('+')
                                vm.percentInflows = (Number(inflow) / (Number(response.data.inflowsData) + Number(inflow)) * 100).toFixed(2)
                            }

                        }
                        if (Number(response.data.signups) > Number(signups)) {
                            $('#signSignUps').text('-')
                            document.getElementById('percentSignUps').className = 'text-red-500';

                            vm.percentSignUps = (Number(response.data.signups) / (Number(response.data.signups) + Number(signups)) * 100).toFixed(2)
                        } else {
                            if (signups == 0) {
                                document.getElementById('percentSignUps').className = 'text-red-500';
                                vm.percentSignUps = 0

                            } else {
                                document.getElementById('percentSignUps').className = 'text-green-500';
                                $('#signSignUps').text('+')
                                vm.percentSignUps = (Number(signups) / (Number(response.data.signups) + Number(signups)) * 100).toFixed(2)
                            }

                        }
                        if (Number(response.data.transactions) > Number(transactions)) {
                            document.getElementById('percentTrans').className = 'text-red-500';
                            $('#signTrans').text('-')

                            vm.percentTransactions = (Number(response.data.transactions) / (Number(response.data.transactions) + Number(transactions)) * 100).toFixed(2)
                        } else {
                            if (transactions == 0) {
                                document.getElementById('percentTrans').className = 'text-red-500';
                                vm.percentTransactions = 0
                            } else {
                                document.getElementById('percentTrans').className = 'text-green-500';
                                $('#signTrans').text('+')
                                vm.percentTransactions = (Number(transactions) / (Number(response.data.transactions) + Number(transactions)) * 100).toFixed(2)
                            }

                        }
                        if (Number(response.data.pAndLData) > Number(pandL)) {
                            $('#signPAndL').text('')
                            document.getElementById('percentPAndL').className = 'text-red-500';

                            vm.percentpAndL = (Number(response.data.pAndLData) / (Number(response.data.pAndLData) + Number(pandL)) * 100).toFixed(2)
                        } else {
                            if (pandL == 0) {
                                document.getElementById('percentPAndL').className = 'text-red-500';
                                vm.percentpAndL = 0
                            } else {
                                document.getElementById('percentPAndL').className = 'text-green-500';
                                $('#signPAndL').text('+')
                                vm.percentpAndL = (Number(pandL) / (Number(response.data.pAndLData) + Number(pandL)) * 100).toFixed(2)
                            }

                        }

                    }

                },
                error: function (jQxhr, status, error) {
                    var errorMessage = jQxhr.responseJSON.errors[0];
                    var statustext = jQxhr.responseJSON.message;
                    vm.errorStatus = true;
                    vm.isLoading = false
                    $('#login').text('login')
                    if (!statustext) {
                        vm.errorMessage = "An error occurred. Try again later.";
                    } else {
                        vm.errorMessage = statustext + " " + errorMessage;
                    }
                }
            });

        },
        fetchAccount() {
            let vm = this
            vm.isLoading = true
            $.get({
                url: store.state.rootUrl + 'v1/partner/accounts',
                type: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + vm.$cookies.get("accessToken")
                },
                data: {
                    startDate: vm.startDate30,
                    endDate: vm.endDate30,

                },
                success: function (response) {
                    vm.isLoading = false
                    if (response.responseCode != 200) {
                        vm.errorStatus = true;
                        vm.errorMessage = response.message;
                    } else {
                        vm.accounts = response.data
                    }
                },
                error: function (jQxhr) {
                    var errorMessage = jQxhr.responseJSON.errors[0];
                    var statustext = jQxhr.responseJSON.message;
                    vm.errorStatus = true;
                    vm.isLoading = false
                    $('#login').text('login')
                    if (!statustext) {
                        vm.errorMessage = "An error occurred. Try again later.";
                    } else {
                        vm.errorMessage = statustext + " " + errorMessage;
                    }
                }
            });

        }
    },
    filters: {
        accountStatus(value) {
            if (value == 1) {
                return "Active"
            } else {
                return 'Inactive'
            }
        },
        accountClassStatus(value) {
            if (value == 1) {
                return "bg-green-300"
            } else {
                return "bg-red-300"
            }
        },
        transactionsStatus(value) {
            if (value == 1) {
                return "Delivered"
            } else if (value == 2) {
                return "On Hold"
            } else if (value == 3) {
                return "Rejected"
            } else {
                return "Pending"
            }
        },
        transClassStatus(value) {
            if (value == '1') {
                return "bg-green-200 text-yellow-900"
            } else if (value == '2') {
                return "bg-yellow-200 text-green-900"
            } else if (value == '3') {
                return "bg-red-200 text-red-900"
            } else {
                return "bg-gray-200 text-gray-900"
            }
        },
        formatDate(value) {
            if (!value) return "";
            return moment(value).format("DD MMM YY");
        },
        formatTime(value) {
            if (!value) return "";
            return moment(value).format("HH:mm:");
        },
        formatAmount(value) {
            if (!value) return 0
            return numeral(value).format("0,0.00");
        },

    }
}
</script>

</style>
