<template>
<div class="flex relative h-full">
    <div class="w-1/3 h-full bg-gray-50 py-4 border-r overflow-auto">
        <div class="flex w-full pb-4 px-4 items-center">
            <div class="flex-shrink">
                <div class="text-lg">All Countries</div>
                <div class="text-xs text-gray-500">{{total}} countries</div>
            </div>
            <div class="flex-grow text-right">
                <input class="px-3 py-2 rounded-md border text-xs focus:outline-none text-gray-700 bg-white focus:border-gray-400 ml-2" placeholder="Search" v-model="search" @keyup="searchRecords">
                <input type="date" class="hidden text-xs border px-3 py-2 rounded-md ml-2" placeholder="Start Date" v-model="startDate" />
                <input type="date" class="hidden text-xs border px-3 py-2 rounded-md ml-2" placeholder="End Date" v-model="endDate" />
                <button class="hidden px-3 py-2 rounded-md border text-xs focus:outline-none text-white bg-blue-500 ml-2" @click="showModal()">Add Country</button>
            </div>
        </div>
        <div class="block">
        <loader v-show="isLoading"></loader>
            <div class="gap-3" v-show="!isLoading" v-for="(country, i) in uniqueCountries " :key="country.name+''+i">
                <div class="flex py-3 items-center gap-3 cursor-pointer hover:bg-gray-100 px-4" :class="{'bg-gray-200': selectedCountry != null && selectedCountry.id == country.id}" @click="loadCountry(country)">
                    <img :src="'https://helasend.s3.eu-west-1.amazonaws.com/img/flags/'+country.code.toLowerCase()+'.svg'" class="w-12 h-8 bg-gray-300 flex-shrink" />
                    <div class="flex-grow block">
                        <div class="text-sm">{{country.name}}</div>
                        <div class="text-xs text-gray-400">{{country.currency}}</div>
                    </div>
                    <div class="text-xs text-gray-600" :class="country.status | amlClassStatus"><strong>{{country.status | amlStatus}}</strong></div>
                </div>
            </div>
            <div class="block p-4">
                <button class="mt-4 px-4 py-2 bg-gray-200 text-green-900 text-sm rounded-md shadow-sm border" @click="fetchAMLRules()">Load More</button>
            </div>
        </div>
    </div>
    <div class="w-2/3 h-full p-4 divide-y" v-if="selectedCountry">
        <div class="flex items-center pb-4">
            <div class="flex items-center">
                <img :src="'https://helasend.s3.eu-west-1.amazonaws.com/img/flags/'+selectedCountry.code.toLowerCase()+'.svg'" class="w-12 bg-gray-300 flex-shrink mr-3" />
                <div class="">
                    <div class="text-lg">{{ selectedCountry.name }}</div>
                    <div class="text-xs text-gray-500">{{ selectedCountry.code }}</div>
                </div>
            </div>
            <div class="flex-grow text-right">
                <button class="px-3 py-2 rounded-md border text-xs focus:outline-none text-white bg-yellow-500 ml-2 mr-2" @click="editCountry(selectedCountry)">Edit Country</button>
                <button class="px-3 py-2 rounded-md border text-xs focus:outline-none text-white bg-red-500 ml-2 mr-2" v-if="selectedCountry.status == 1" :id="'action-disable-'+selectedCountry.id" @click="updateCountryState(selectedCountry.id,'disable')">Disable</button>
                <button class="px-3 py-2 rounded-md border text-xs focus:outline-none text-white bg-green-500 ml-2" v-if="selectedCountry.status == 0" :id="'action-activate-'+selectedCountry.id" @click="updateCountryState(selectedCountry.id,'activate')">Activate</button>
            </div>
        </div>

        <div class="grid grid-cols-5 py-4">
            <div class="col-span-1">
                <div class="text-xs"><span class="font-medium">Currency:</span><br />{{ selectedCountry.currency }}</div>
            </div>
            <div class="col-span-1">
                <div class="text-xs"><span class="font-medium">Source Status:</span><br />{{ selectedCountry.source | amlStatus}}</div>
            </div>
            <div class="col-span-1">
                <div class="text-xs"><span class="font-medium">Destination Status:</span><br />{{ selectedCountry.destination | amlStatus}}</div>
            </div>
            <div class="col-span-1">
                <div class="text-xs"><span class="font-medium">Fees:</span><br />{{ selectedCountry.fees }}</div>
            </div>
            <div class="col-span-1">
                <div class="text-xs"><span class="font-medium">Tax:</span><br />{{ selectedCountry.tax }}</div>
            </div>
        </div>

        <div class="block py-4">
            <div class="text-md font-medium">Pay ins</div>
            <table class="w-full rounded-md border text-xs mt-2">
                <thead>
                    <tr class="bg-gray-100 text-left border-b">
                        <th class="px-2 py-2 border-r">Mode</th>
                        <th class="px-2 py-2 border-r">Type</th>
                        <th class="py-2 px-2 border-r text-right">Min</th>
                        <th class="py-2 px-2 text-right">Max</th>
                        <th class="py-2 px-2 w-1"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="border-b" v-for="(payIn,index) in payins" :key="index" v-show="!isLoading">
                        <td class="py-2 px-2 border-r">{{payIn.mode}}</td>
                        <td class="py-2 px-2 border-r">{{payIn.type}}</td>
                        <td class="py-2 px-2 border-r text-right">{{payIn.minimum | formatNumber}}</td>
                        <td class="py-2 px-2 border-r text-right">{{payIn.maximum | formatNumber}}</td>
                        <td class="py-2 px-2 text-center"><button class="px-3 py-2 rounded-md border text-xs focus:outline-none text-gray-700 bg-gray-200 ml-2">Disable</button></td>
                    </tr>

                </tbody>
            </table>
        </div>
        <div class="block py-4">
            <div class="text-md font-medium">Pay Outs</div>
            <table class="w-full rounded-md border text-xs mt-2">
                <thead>
                    <tr class="bg-gray-100 text-left">
                        <th class="px-2 py-2 border-r">Mode</th>
                        <th class="px-2 py-2 border-r">Type</th>
                        <th class="py-2 px-2 border-r text-right">Min</th>
                        <th class="py-2 px-2 text-right">Max</th>
                        <th class="py-2 px-2 w-1"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="border-b" v-for="(payOut,index) in payOuts" :key="index" v-show="!isLoading">
                        <td class="py-2 px-2 border-r">{{payOut.payoutMode}}</td>
                        <td class="py-2 px-2 border-r">{{payOut.payoutType}}</td>
                        <td class="py-2 px-2 border-r text-right">{{payOut.maximum | formatNumber}}</td>
                        <td class="py-2 px-2 border-r text-right">{{payOut.minimum | formatNumber}}</td>
                        <td class="py-2 px-2 text-center"><button class="px-3 py-2 rounded-md border text-xs focus:outline-none text-gray-700 bg-gray-200 ml-2">Disable</button></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <AddRegion v-show="this.modal" />
    <ModalEditCountry v-show="modalEditCountry" />
</div>
</template>

<script>
import $ from "jquery";
import ModalEditCountry from "./modal-edit-country"
import store from "../store";
import AddRegion from "./modal-add-region";
import moment from "moment";
import loader from "@/components/loader";
import numeral from "numeral";

export default {
    components: {
        AddRegion,
        loader,
        ModalEditCountry
    },
    data() {
        return {
            search: "",
            modal: false,
            modalEditCountry: false,
            countries: [],
            sort: "desc",
            page: 0,
            total: 0,
            limit: 30,
            errorStatus: false,
            errorMessage: "",
            startDate: "",
            endDate: "",
            dateFormat: "dd-mm-yyyy",
            isLoading: false,
            selectedCountry: null,
            payins: [],
            payOuts: []
        }
    },
    mounted() {
        this.endDate = moment().format("YYYY-MM-DD")
        this.startDate = moment().subtract(7, 'd').format('YYYY-MM-DD');
        this.fetchAMLRules()

    },
     computed: {
        uniqueCountries: function() {
          var Remove_duplicate_Value = [];
          for(var i =0; i < this.countries.length; i++) {
            if(Remove_duplicate_Value.indexOf(this.countries[i].name) === -1) {
              Remove_duplicate_Value.push(this.countries[i])
            }
          }
        return Remove_duplicate_Value;
        }
      },
    methods: {
        myFilter: function (val, idx, arr) {
            console.log("am here")
          for(var i = 0; i < idx; i++) {
            if(arr[i].name === val.name) {
              return false;
            }
          }
          return true;
        },
        showModal() {
            this.modal = true;
        },
        searchRecords() {
            let vm = this
            vm.countries = []
            vm.total = 0
            vm.page = 0
            vm.fetchAMLRules()
        },
        editCountry(selectedCountry) {
            store.commit("setCountryData", selectedCountry);
            this.modalEditCountry = true
        },
        fetchAMLRules() {
            let vm = this
            if (vm.total === 0 || vm.total >= ((vm.page - 1) * vm.limit)) {
                vm.isLoading = true
                vm.page++
                $.get({
                    url: store.state.rootUrl + 'v1/dashboard/countries',
                    type: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + vm.$cookies.get("accessToken")
                    },
                    data: {
                        search: vm.search,
                        sort: vm.sort,
                        page: vm.page,
                        limit: vm.limit,
                        startDate: vm.startDate,
                        endDate: vm.endDate,
                        status: 10
                    },
                    success: function (response, status, jQxhr) {
                        vm.isLoading = false
                        if (response.responseCode != 200) {
                            vm.errorStatus = true;
                            vm.errorMessage = response.message;
                        } else {
                            var list_c = response.data;
                            for (var i = 0; i < list_c.length; i++) {
                                vm.countries.push(list_c[i])
                            }
                            vm.total = response.total
                        }
                    },
                    error: function (jQxhr, status, error) {
                        var errorMessage = jQxhr.responseJSON.errors[0];
                        var statustext = jQxhr.responseJSON.message;
                        vm.errorStatus = true;
                        vm.isLoading = false
                        $('#login').text('login')
                        if (!statustext) {
                            vm.errorMessage = "An error occurred. Try again later.";
                        } else {
                            vm.errorMessage = statustext + " " + errorMessage;
                        }
                    }
                });

            }
        },
        updateCountryState(countryID, state) {
            let vm = this

            $('#action-' + state + "-" + countryID).html('<i class="fa fa-spinner fa-spin"></i> Please wait ...');
            $.post({
                url: store.state.rootUrl + 'v1/dashboard/countries/action',
                type: "POST",

                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + vm.$cookies.get("accessToken")
                },
                data: JSON.stringify({
                    countryID: countryID,
                    action: state,

                }),
                success: function (response, status, jQxhr) {
                    // console.log(JSON.stringify(response.data.data));
                    if (state == 'activate') {
                        $('#action-' + state + "-" + countryID).text("Disabled")
                    } else {
                        $('#action-' + state + "-" + countryID).text("Activate")
                    }

                    if (response.responseCode != 200) {

                        vm.errorStatus = true;
                        vm.errorMessage = response.message;
                    } else {
                        if (state == 'disable') {
                            vm.selectedCountry.status = 0
                        } else {
                            vm.selectedCountry.status = 1
                        }
                        vm.searchRecords()
                    }

                },
                error: function (jQxhr, status, error) {
                    var errorMessage = jQxhr.responseJSON.errors[0];
                    var statustext = jQxhr.responseJSON.message;
                    vm.errorStatus = true;
                    $('#action-' + state + "-" + countryID).text(state)
                    if (!statustext) {
                        vm.errorMessage = "An error occurred. Try again later.";
                    } else {
                        vm.errorMessage = statustext + " " + errorMessage;
                    }
                }
            });
        },
        loadCountry(val) {
            var vm = this;
            vm.payOuts = []
            vm.payins = []
            vm.selectedCountry = val;
            console.log("am here test " + JSON.stringify(val.code))
            vm.isLoading = true

            $.get({
                url: store.state.rootUrl + 'v1/countries/payins/' + val.code,
                type: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + vm.$cookies.get("accessToken")
                },
                data: {
                    search: vm.search,
                    sort: vm.sort,
                    page: vm.page,
                    limit: vm.limit,
                    startDate: vm.startDate,
                    endDate: vm.endDate,
                    status: 10
                },
                success: function (response, status, jQxhr) {
                    vm.isLoading = false
                    if (response.responseCode != 200) {
                        vm.errorStatus = true;
                        vm.errorMessage = response.message;
                    } else {
                        var list_c = response.payins;
                        for (var i = 0; i < list_c.length; i++) {
                            vm.payins.push(list_c[i])
                        }
                    }
                },
                error: function (jQxhr, status, error) {
                    var errorMessage = jQxhr.responseJSON.errors[0];
                    var statustext = jQxhr.responseJSON.message;
                    vm.errorStatus = true;
                    vm.isLoading = false
                    $('#login').text('login')
                    if (!statustext) {
                        vm.errorMessage = "An error occurred. Try again later.";
                    } else {
                        vm.errorMessage = statustext + " " + errorMessage;
                    }
                }
            });

            $.get({
                url: store.state.rootUrl + 'v1/countries/payouts/' + val.code,
                type: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + vm.$cookies.get("accessToken")
                },
                data: {
                    search: vm.search,
                    sort: vm.sort,
                    page: vm.page,
                    limit: vm.limit,
                    startDate: vm.startDate,
                    endDate: vm.endDate,
                    status: 10
                },
                success: function (response, status, jQxhr) {
                    vm.isLoading = false
                    if (response.responseCode != 200) {
                        vm.errorStatus = true;
                        vm.errorMessage = response.message;
                    } else {
                        var list_c = response.payouts;
                        for (var i = 0; i < list_c.length; i++) {
                            vm.payOuts.push(list_c[i])
                        }
                    }
                },
                error: function (jQxhr, status, error) {
                    var errorMessage = jQxhr.responseJSON.errors[0];
                    var statustext = jQxhr.responseJSON.message;
                    vm.errorStatus = true;
                    vm.isLoading = false
                    if (!statustext) {
                        vm.errorMessage = "An error occurred. Try again later.";
                    } else {
                        vm.errorMessage = statustext + " " + errorMessage;
                    }
                }
            });
        }
    },
    filters: {
        amlStatus(value) {
            if (value == 1) {
                return "Active"
            } else {
                return "Disabled"
            }
        },
        amlClassStatus(value) {
            if (value == '1') {
                return "text-green-500"
            } else {
                return "text-red-500"
            }
        },
        formatNumber(value) {
            return numeral(value).format("0,0");
        }
    }
}
</script>
