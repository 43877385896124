<template>
<div class="absolute left-0 top-0 bottom-0 w-full h-full bg-gray-100 align middle">
    <div class="m-auto max-w-sm p-8 rounded bg-white md:mt-48">
        <div class="text-2xl">Login</div>
        <div class="block mt-4">
            <div v-show="loginStatus" class="text-red-500 px-2 py-1 rounded-sm bg-red-100 text-xs">
                {{loginStatusMessage}}
            </div>
        </div>
        <form @submit.prevent="goToDashboard">
            <div class="block mt-4">
                <div class="text-gray-700 text-sm">Email Address</div>
                <input class="w-full outline-none border-2 rounded-md px-3 py-2 mt-1 focus:bg-blue-50" placeholder="mail@example.com" type="email" v-model="email" />
            </div>
            <div class="block mt-4">
                <div class="text-gray-700 text-sm">Password</div>
                <input class="w-full outline-none border-2 rounded-md px-3 py-2 mt-1 focus:bg-blue-50" placeholder="••••••••••••" type="password" v-model="password" />
            </div>
            <div class="block mt-4">
                <button type="submit" class="px-3 py-2 text-center w-full bg-green-500 text-white rounded-md" id="login">Log in</button>
            </div>
            <!-- <div class="mt-4 hidden"><router-link :to="{name: 'forgot-password'}">Forgot password</router-link></div> -->
           
        </form>
    </div>
</div>
</template>

<script>
import $ from 'jquery'
import store from "../store";
export default {
    data: function () {
        return {
            email: '',
            password: '',
            loginStatus: false,
            loginStatusMessage: '',
        }
    },
    methods: {
        goToDashboard() {
            let vm = this

            $('#login').html('<i class="fa fa-spinner fa-spin"></i> Please wait ...');
            $.post({
                url: store.state.rootUrl + 'v1/login',
                type: "POST",

                headers: {
                    "Content-Type": "application/json"
                },
                data: JSON.stringify({
                    email: vm.email,
                    password: vm.password,

                }),
                success: function (response, status, jQxhr) {
                    // console.log(JSON.stringify(response.data.data));

                    if (response.responseCode != 200) {
                        $('#login').text('login')
                        vm.loginStatus = true;
                        vm.loginStatusMessage = response.message;
                    } else {
                        vm.$cookies.set("accessToken", response.token);
                        vm.$cookies.set("UserRole", response.user.role);
                        vm.$cookies.set("roleID",response.user.role_id);
                        vm.$cookies.set("email", vm.email);
                        vm.$cookies.set("userID", response.user.id);
                        if (response.user.role_id == 2) {

                             vm.$router.push({
                                name: 'tickets'
                            })
                        } else {

                            vm.$router.push({
                                name: 'dashboard'
                            });
                        }
                    }

                },
                error: function (jQxhr, status, error) {
                    var statustext = jQxhr.responseJSON.message;
                    vm.loginStatus = true;
                    $('#login').text('login')
                    if (!statustext) {
                        vm.loginStatusMessage = "An error occurred. Try again later.";
                    } else {
                        vm.loginStatusMessage = statustext;
                    }
                }
            });

        },

    }
}
</script>
