import Vue from 'vue';
import VueRouter from 'vue-router';
import Index from './components';
import Login from './pages/login.vue';
import ForgotPassword from './pages/forgot-password.vue';
import Verify from './pages/verify.vue';
import ChangePassword from './pages/change-password.vue';

import DashboardIndex from './dashboard/index';
import Dashboard from './dashboard/general-report';

import TransactionsIndex from './transactions/index';
import Transactions from './transactions/transactions';
import PendingTransactions from './transactions/pending';
import RejectedTransactions from './transactions/rejected';
import CheckedTransactions from './transactions/checked';
import SuccessTransaction from './transactions/success';
import RefundedTransaction from './transactions/refunded'

import FinanceIndex from './internal/index';
import Forex from './internal/forex';
import Accounts from './internal/accounts';
import Tarrifs from './internal/tarrifs';
import Countries from './internal/countries';
import AMLRules from './internal/aml-rules';

import UsersIndex from './users/index';
import Users from './users/users';
import UsersBlocked from './users/blocked';
import UsersPending from './users/pending-approval';

import AMLIndex from './aml/index';


import Settings from './settings';
import SystemUser from './settings/view-user'
import SystemAffialites from './settings/view-user-affiliate'


import TicketsIndex from './tickets/index';
import TicketsSearch from './tickets/search';
import TicketsAll from './tickets/all';
import TicketsUnresolved from './tickets/unresolved';

Vue.use(VueRouter)

const routes = [
    { path: '/', name: 'login', component: Login },
    { path: '/forgot', name: 'forgot-password', component: ForgotPassword },
    { path: '/verify', name: 'verify', component: Verify },
    { path: '/change-password', name: 'change-password', component: ChangePassword },
    { path: '/app', component: Index, children:[
        { path: '', component: DashboardIndex, children: [
                { path: '', name: 'dashboard', component: Dashboard, meta: { routeName: 'General Report' } },
            ] },
        { path: 'transactions', component: TransactionsIndex, children: [
                {  path: '', name: 'transactions', component: Transactions, meta: { routeName: 'Transactions' } },
                {  path: 'pending', name: 'pending-transactions', component: PendingTransactions, meta: { routeName: 'Pending Transactions' } },
                {  path: 'rejected', name: 'rejected-transactions', component: RejectedTransactions, meta: { routeName: 'Rejected' } },
                {  path: 'checked', name: 'checked-transactions', component: CheckedTransactions, meta: { routeName: 'AML Checked Transactions' } },
                {  path: 'success', name: 'success-transactions', component: SuccessTransaction, meta: { routeName: 'Success Transactions' } },
                {  path: 'refunded', name: 'refunded-transactions', component: RefundedTransaction, meta: { routeName: 'Refunded Transactions' } },
                
            ]},
        { path: 'users', component: UsersIndex, children: [
                { path: '', name: 'users', component: Users, meta: { routeName: 'All Users' } },
                { path: 'blocked', name: 'blocked-users', component: UsersBlocked, meta: { routeName: 'Blocked Users' } },
                { path: 'approval/pending', name: 'approve-pending-users', component: UsersPending, meta: { routeName: 'Pending Approve Users' } },
            ] },
        { path: 'finance', component: FinanceIndex, children: [
                { path: 'forex', name: 'forex', component: Forex, meta: { routeName: 'Forex Rates' } },
                { path: 'accounts', name: 'accounts', component: Accounts, meta: { routeName: 'Accounts' } },
                { path: 'tarrifs', name: 'tarrifs', component: Tarrifs, meta: { routeName: 'Tarrifs' } },
                { path: 'countries', name: 'countries', component: Countries, meta: { routeName: 'Countries' } },
                { path: 'aml-rules', name: 'aml-rules', component: AMLRules, meta: { routeName: 'AML' } },
            ] },
        { path: 'aml', component: AMLIndex, children: [
                
            ] },
        { path: 'settings', component: Settings, children:[
            { path: 'users', name: 'view-system-user', component: SystemUser, meta: { routeName: 'View User' } },
            { path: 'affiliates', name: 'view-system-affiliates', component: SystemAffialites, meta: { routeName: 'View Affiliates' } },
        ]},
        { path: 'tickets', component: TicketsIndex, children: [
                { path: '', name: 'tickets', component: TicketsAll, meta: { routeName: 'All issues' } },
                { path: 'unresolved', name: 'tickets-unresolved', component: TicketsUnresolved, meta: { routeName: 'Unresolved issues' } },
                { path: 'search', name: 'tickets-search', component: TicketsSearch, meta: { routeName: 'Search' } },
            ] },
    ]},
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router;
