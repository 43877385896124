<template>
<div class="fixed left-0 top-0 bottom-0 w-full z-1 bg-black bg-opacity-10 transition" id="aml-rules-add">
    <div class="absolute top-0 bottom-0 right-0 w-1/3 bg-white z-2 p-4 overflow-auto">
        <div class="block mb-4">
            <div class="text-lg">Add Affiliate</div>
            <div v-show="errorSatus" class="text-red-500 px-2 py-1 rounded-sm bg-red-100 text-xs">
                {{errorMessage}}
            </div>
        </div>
        <form @submit.prevent="updateAccount">
            <div class="block my-6">
                <label for="" class="text-sm mb-1 block">First Name</label>
                <input class="text-xs border border-gray-300 px-3 py-2 w-full rounded-md focus:outline-none" v-model="fname" required />
            </div>
             <div class="block my-6">
                <label for="" class="text-sm mb-1 block">Last Name</label>
                <input class="text-xs border border-gray-300 px-3 py-2 w-full rounded-md focus:outline-none" v-model="lname" required />
            </div>
            <div class="block my-6">
                <label for="" class="text-sm mb-1 block">Email</label>
                <input type="email" class="text-xs border border-gray-300 px-3 py-2 w-full rounded-md focus:outline-none" v-model="email" required />
            </div>
            <div class="block my-6">
                <label for="" class="text-sm mb-1 block">Phone</label>
                <input class="text-xs border border-gray-300 px-3 py-2 w-full rounded-md focus:outline-none"  v-model="phone" required />
            </div>
            <div class="block">
                <label for="" class="text-sm mb-1 block">Countries</label>
                <select class="text-xs border border-gray-300 px-3 py-2 w-full block rounded-md focus:outline-none" v-model="country" required>
                    <option v-for="(country,index) in countries" :key="index" :value="country.name">{{country.name}}</option>
                </select>
            </div>
            <div class="block my-6">
                <label for="" class="text-sm mb-1 block">City</label>
                <input class="text-xs border border-gray-300 px-3 py-2 w-full rounded-md focus:outline-none"  v-model="city" required />
            </div>

            <div class="block mt-16">
                <button class="px-3 py-2 rounded-md border text-sm focus:outline-none text-gray-700 bg-gray-200 mr-4" type="button" @click="hideModal">Cancel</button>
                <button class="px-3 py-2 rounded-md border text-sm focus:outline-none text-white bg-green-500" type="submit" id="updateAccount">Approve Account</button>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import $ from "jquery";
import store from "../store";
export default {
    data: function () {
        return {
            fname: '',
            lname: '',
            email: '',
            phone: '',
            countries: [],
            country:'',
            city:'',
            type:'',
            errorMessage: "",
            errorSatus: false,
        }
    },
    computed: {
    },
    watch: {
    },
    mounted() {
        this.loadContries()
    },
    methods: {
        hideModal() {
            this.$parent.approveModal = false;
        },
        loadContries(){
            let vm  = this
            vm.countries = []
            $.get({
                    url: store.state.rootUrl + 'v1/dashboard/countries',
                    type: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + vm.$cookies.get("accessToken")
                    },
                    data: {
                        limit: 250
                    },
                    success: function (response, status, jQxhr) {
                        vm.isLoading = false
                        if (response.responseCode != 200) {
                            vm.errorStatus = true;
                            vm.errorMessage = response.message;
                        } else {
                            var list_c = response.data;
                            for (var i = 0; i < list_c.length; i++) {
                                if(list_c[i].source == 1){
                                    vm.countries.push(list_c[i])
                                }
                            }
                        }
                    },
                    error: function (jQxhr, status, error) {
                        var errorMessage = jQxhr.responseJSON.errors[0];
                        var statustext = jQxhr.responseJSON.message;
                        vm.errorStatus = true;
                        vm.isLoading = false
                        $('#login').text('login')
                        if (!statustext) {
                            vm.errorMessage = "An error occurred. Try again later.";
                        } else {
                            vm.errorMessage = statustext + " " + errorMessage;
                        }
                    }
                });
        },
        updateAccount() {
            let vm = this
            var roleName=""
            if(vm.type == 1){
                roleName = "Administrator"
            }
            else if(vm.type==3){
                roleName = "Finance"
            }
            else {
                roleName = "Support"
            }
            $('#updateAccount').html('<i class="fa fa-spinner fa-spin"></i> Please wait ...');
            $.post({
                url: store.state.rootUrl + 'v1/affiliates/create',
                type: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + vm.$cookies.get("accessToken")
                },
                data: JSON.stringify({
                    affliateFirstname: vm.fname,
                    affiliateLastname: vm.lname,
                    affiliateEmail: vm.email,
                    affiliatePhone: vm.phone,
                    affiliateCountry: vm.country,
                    affiliateCity: vm.city

                }),
                success: function (response) {

                    $('#updateAccount').text('Approve Account')
                    if (response.responseCode != 200) {
                        vm.errorSatus = true;
                        vm.errorMessage = response.message;
                    } else {
                        vm.hideModal()
                        vm.$parent.$refs.vuetable.refresh();
                    }
                },
                error: function (jQxhr, status, error) {
                    var errorMessage = jQxhr.responseJSON.errors[0];
                    var statustext = jQxhr.responseJSON.message;
                    vm.errorSatus = true;
                    $('#createRule').text('Approve Account')
                    if (!statustext) {
                        vm.errorMessage = "An error occurred. Try again later.";
                    } else {
                        vm.errorMessage = statustext + " " + errorMessage;
                    }
                }
            });
        },
    },

}
</script>
